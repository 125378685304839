
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { AxiosError } from "axios";
import { showToast } from "../../util";
import { doCognitoSignIn } from "../../util";
import { postCognitoSignin, postSignin } from "../../networking/apiFunctions/serviceApiFunctions";
import { useAppLayoutContext } from "../../contexts/LayoutContext";

const Login = (props) => {
  const { setFormType, setIsUserLoggedIn } = props;
  const navigate = useNavigate();
  const { showOverlay, hideOverlay } = useAppLayoutContext()

  let formData = { emailId: "", password: "" };
  const [loginData, setLoginData] = useState(formData);
  const onChangeData = (e) => {
    let id = e.target.id;
    setLoginData((loginData) => ({
      ...loginData,
      [id]: e.target.value,
    }));
  };

  const callLoginAPI = async (json) => {
    showOverlay()
    try {
      const loginResult = await postSignin(json)
      setIsUserLoggedIn(true)
      localStorage.setItem("isLogin", true)
      localStorage.setItem("isCandidateAssessmentCompleted", false)
      const data = JSON.stringify(loginResult)
      localStorage.setItem("userValues", data)

      await doCognitoSignIn(loginResult)
      navigate("/dashboard")

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log("error", err);
        let errormsg = "Oops!! Login failed, please enter valid userId and password."
        showToast({ type: "error", message: errormsg })
      }
    } finally {
      hideOverlay()
    }
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    // If username or password missing, do nothing
    if (!loginData.emailId || !loginData.password) {
      return;
    }

    let json = {
      username: loginData.emailId,
      password: btoa(loginData.password),
    };

    handleCognitoSignin(json)
  };

  const handleCognitoSignin = async (Payload) => {


    try {
      showOverlay()
      const result = await postCognitoSignin(Payload)
      setIsUserLoggedIn(true);
      localStorage.setItem("isLogin", true);
      const user_info = JSON.stringify(result.user);
      localStorage.setItem("userValues", user_info);
      let jwt = JSON.stringify(result.jwtToken);
      localStorage.setItem("jwt", jwt.replaceAll('"', ''));

      (localStorage.getItem('browerConcurrentLogin')) && (parseInt(localStorage.getItem('browerConcurrentLogin')) > 1) ?
        localStorage.setItem("browerConcurrentLogin", (parseInt(localStorage.getItem('browerConcurrentLogin')) + 1))
        : localStorage.setItem("browerConcurrentLogin", 1)


      navigate("/dashboard");

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log("error", err.message)
        showToast({ type: "error", message: err.message })
      }

    } finally {
      hideOverlay()
    }

  }


  return (
    <div className="container login-container">
      <div className="row">
        <div className="col-md-3" />
        <div className="col-md-6 login-form-1">
          <h3>Login</h3>
          <form onSubmit={handleLoginSubmit}>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email *"
                value={loginData.emailId}
                id="emailId"
                title="Please enter a valid email"
                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                required
                onChange={(e) => {
                  onChangeData(e);
                }}
                onInvalid={F => F.target.setCustomValidity('Please enter a valid email')}
                onInput={F => F.target.setCustomValidity('')}
              />
            </div>
            <br />
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Your Password *"
                value={loginData.password}
                id="password"
                required
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </div>
            <br />
            <div className="form-group" style={{ paddingLeft: "30%" }}>
              <input type="submit" className="btnSubmit" value="Login" />
            </div>{" "}
            <br />
          </form>
        </div>
        <div className="col-md-3" />
      </div>
    </div>
  );
};
export default Login;
