import ReactDataGrid from "@inovua/reactdatagrid-community";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import "@inovua/reactdatagrid-community/index.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import ModalComponent from "../../components/ModalComponent";
import { getSoftSkillsList, postCreateSoftSkill } from "../../networking/apiFunctions/serviceApiFunctions";
import { AxiosError } from "axios";
import { deleteSoftSkill } from "../../networking/apiFunctions/adminApiFunctions";
window.moment = moment;

const gridStyle = { minHeight: 550 };

export default function ManageSkills() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModel, setshowConfirmationModel] = useState(false);
  const [rowId, setRowId] = useState(null);
  const { updateBreadcrumbData, showOverlay, hideOverlay } = useAppLayoutContext();

  const breadcrumbs = [
    {
      route: "/dashboard",
      title: "Home",
    },
    {
      route: "/manage-skills",
      title: "Manage Skills",
      active: true,
    },
  ];

  const fetchSoftSkillList = async () => {
    if (loading) { return }
    try {
      setLoading(true);
      const result = getSoftSkillsList()
      setDataSource(result);
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        showToast({ message: "Oops!! Failed to fetch soft skills.", type: "error" });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // call api and update the datasource
    updateBreadcrumbData(breadcrumbs);
    fetchSoftSkillList();
  }, []);

  const columns = [
    {
      name: "softSkillId",
      header: "Skill Id",
      // defaultWidth: 110,
      flex: 1
    },
    {
      name: "softSkillDescription",
      header: "Skill Description",
      // defaultWidth: 200,
      flex: 1
    },
    {
      name: "status",
      header: "Status",
      // defaultWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: [
          { id: "Active", label: "Active" },
          { id: "Inactive", label: "Inactive" },
        ],
      },
      render: ({ value }) => {
        return <span className="text-success">{value ? "Active" : "Inactive"}</span>;
      },
      flex: 1
    },

    {
      header: "Actions",
      defaultFlex: 1,
      render: ({ data }) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                console.log('data >>', data);
                setRowId(data.softSkillId);
                onConfirmationModalOpen(true);
              }}
            >
              Delete
            </button>
          </div>
        );
      },
      flex: 1
    },
  ];
  const filterValue = [
    { name: "softSkillId", operator: "contains", type: "string", value: "" },
    { name: "softSkillDescription", operator: "contains", type: "string", value: "" },
    { name: "status", operator: "eq", type: "select", value: null },
  ];
  const onConfirmationModalClose = () => {
    setRowId(null);
    setshowConfirmationModel(false);
  };
  const onConfirmationModalSubmit = async () => {
    if (loading) { return }

    try {
      if (rowId) {
        showOverlay()
        setLoading(true)
        const result = await deleteSoftSkill(rowId)
        fetchSoftSkillList();
        setRowId(null);
        setshowConfirmationModel(false);
        showToast({ type: "success", message: result });
      }

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        showToast({ type: "error", message: err.message })
      }

    } finally {
      setshowConfirmationModel(false);
      setLoading(false)
      hideOverlay()
    }
  }

  const onConfirmationModalOpen = () => {
    setshowConfirmationModel(true);
  };
  const [formObject, setFormObject] = useState({
    status: { value: "Active", validation: true },
    softSkillDescription: { value: "" },
  });

  const onChangeData = (event) => {
    let newformObject = { ...formObject };
    let subObj = {};
    subObj["value"] = event.target.value;
    subObj["validation"] = true;
    if (!event.target.value) {
      subObj["validation"] = false;
    }
    newformObject[`${event.target.id}`] = subObj;
    setFormObject(newformObject);
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    if (loading) { return }


    let validation = false;
    for (const key in formObject) {
      if (formObject[key].validation) {
        validation = true;
      } else {
        validation = false;
        formObject[key].validation = false;
      }
    }
    if (!validation) {
      setFormObject({ ...formObject });
    }
    if (!validation) {
      showToast({ type: "error", message: `Mandatory fields are required` });
    } else {
      let body = {
        softSkillDescription: formObject.softSkillDescription?.value,
        status: formObject.status?.value === "Active" ? true : false,
      };


      try {
        showOverlay()
        setLoading(true)
        const result = await postCreateSoftSkill(body)
        setFormObject({
          softSkillDescription: { value: "" },
          status: { value: "Active", validation: true },
        })
        fetchSoftSkillList();
        showToast({ type: "success", message: "Skill has been created Successfully" });


      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          console.log(err)
          showToast({ type: "error", message: "Oops!! Something went wrong" });
        }
      } finally {
        setLoading(false)
        hideOverlay()
      }
    }


  };
  const status = ["Active", "Inactive"];
  return (
    <>
      <h4 className="items-center d-flex my-4 justify-content-center">
        Manage Skills
      </h4>
      <div className="container skill-container">
        <h2>Add Skills</h2>
        <form className="form-inline" onSubmit={(e) => {
          onSubmit(e)
        }}>
          <div className="row">
            <div className="col-md-6">
              {/* <label for="softSkillDescription" className="sr-only">Skill Description</label> */}
              {/* <input type="text" className="form-control" id="softSkillDescription" value={formObject.softSkillDescription?.value} placeholder="Please Enter skill description" /> */}
              <input
                type="text"
                className="form-control"
                placeholder="Please Enter skill *"
                value={formObject.softSkillDescription?.value}
                id="softSkillDescription"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {formObject.softSkillDescription?.validation === false && (
                <p className="red">Please enter skill</p>
              )}
            </div>
            <div className="col-md-4">
              <label for="status" className="sr-only">Status</label>
              <select
                className="form-control form-select"
                id="status"
                onChange={(e) => {
                  onChangeData(e);
                }}
              >
                {status.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
              {formObject.status?.validation === false && (
                <p className="red">Please select status</p>
              )}
            </div>
            <div className="col-md-2">
              <button type="submit" className="btn btn-primary mb-2">Submit</button>
            </div>
          </div>
        </form>
      </div>
      <div className="container">
        <div className="row">
          <ReactDataGrid
            columns={columns}
            dataSource={dataSource}
            idProperty="softSkillId"
            style={gridStyle}
            pagination
            defaultLimit={100}
            loading={loading}
            enableColumnAutosize={true}
            defaultFilterValue={filterValue}
          // enableSelection={true}
          />
        </div>
      </div>
      {showConfirmationModel ? (<ModalComponent
        title={"Delete Confirmation"}
        onCloseClick={onConfirmationModalClose}
        showFooter={true}
        modalId={"confirmationSubmitModal"}
        modalBody={
          <div className="container text-center">
            <div className="row">
              <div className="col-12 pb-4">
                <h5 className="card-title text-center text-capitalize mb-4">
                  Are you sure you want to delete this item?
                </h5>
              </div>
            </div>
          </div>
        }
        footer={
          <>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onConfirmationModalClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConfirmationModalSubmit}
            >
              Delete
            </button>
          </>
        }
      />) : null}
    </>
  );
}
