import { useEffect, useState } from "react";
import CardComponent from "../cardComponent/CardComponent";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/recommendations",
    title: "Training Recommendations",
    active: true,
  },
];
const trainingsl1 = [
  {
    trainingName: "JavaScript",
    summary: "This tutorial will teach you JavaScript from basic to advanced.",
    url: "https://www.w3schools.com/js/default.asp",
  },
  {
    trainingName: "React",
    summary: "This tutorial will teach you React from basic to advanced.",
    url: "https://www.w3schools.com/react/default.asp",
  },
  {
    trainingName: "Git",
    summary: "This tutorial will teach you Git from basic to advanced.",
    url: "https://www.w3schools.com/git/default.asp",
  },
];
export default function Recommendations() {
  const { updateBreadcrumbData } = useAppLayoutContext();
  //   const [level, setLevel] = useState(1);
  const [trainings, setTrainings] = useState(trainingsl1);
  useEffect(() => {
    updateBreadcrumbData(breadcrumbs);
  }, []);

  return (
    <>
      <div className="container">
        <h4 className="my-4 text-center">Training recommendations</h4>
        <br />
        <div className="row mt-4">
          {trainings.map((item, index) => {
            return (
              <div className="col-12 col-sm-2 col-md-4" key={index}>
                <CardComponent
                  title={item.trainingName}
                  link={item.url}
                  btnText={"View"}
                  contentAlign="center"
                  cardBody={item.summary}
                  linkTarget={"_blank"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
