import React from "react";
import "./Login.css";
import { AxiosError } from "axios";
import Helpers from "./Helper";
import { postSignup } from "../../networking/apiFunctions/serviceApiFunctions";
import { showToast } from "../../util";

const Signup = (props) => {
  const { setFormType } = props;
  const [typeOfUser, setTypeOfuser] = React.useState("");

  const userTypeList = [
    "Select User Type *",
    "Admin",
    "Candidate",
    "Employee",
    "Client",
  ];

  const primarySkillList = [
    "Select Primary Skill *",
    "React",
    "JavaScipt",
    "Angular",
  ];

  const [formObject, setFormObject] = React.useState({});
  const yearOfExperience = [
    "Select Year Of Experience *",
    "0-3",
    " 3-6",
    "6-9",
  ];
  const onChangeData = (event) => {
    let newformObject = { ...formObject };
    let subObj = {};
    subObj["value"] = event.target.value;
    if (event.target.id === "userName" || event.target.id === "userEmail") {
      subObj["validation"] = Helpers.validateEmail(event.target.value);
    } else {
      subObj["validation"] = false;
    }
    newformObject[`${event.target.id}`] = subObj;
    setFormObject(newformObject);
  };

  const onSubmit = () => {
    let json = {
      userType: typeOfUser,
      userName: formObject.userName?.value,
      userEmail: formObject.userEmail?.value,
      userPassword: formObject.createPassword?.value,
      confirmPassword: formObject.confirmPassword?.value,
      yearOfExperience: formObject.yearOfExperience?.value,
      skill: formObject.primarySkill?.value,
    }
    handleSignupApiCall(json)
  }

  const handleSignupApiCall = async (payload) => {
    try {
      await postSignup(payload)
      setFormType("login")
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err);
        console.error("Sign up", err);
        showToast({ type: "error", message: "Sign up failed." })
      }
    }
  }

  return (
    <div className="container login-container">
      <div className="row">
        <div className="col-md-3" />
        <div className="col-md-6 login-form-1">
          <h3>Signup</h3>
          <div>
            <div className="form-group">
              <select
                className="form-control form-select"
                id="userType"
                onChange={(e) => {
                  setTypeOfuser(e.target.value);
                }}
                value={typeOfUser}
              >
                {userTypeList.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>
            <br />
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Username *"
                value={formObject.userName?.value}
                id="userName"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {/* {formObject.userName?.validation === false && (
                <p className="red">Please enter a valid email id</p>
              )} */}
            </div>
            <br />
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Email *"
                value={formObject.userEmail?.value}
                id="userEmail"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {formObject.userEmail?.validation === false && (
                <p className="red">Please enter a valid email id</p>
              )}
            </div>
            <br />
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Create Password *"
                value={formObject.createPassword?.value}
                id="createPassword"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </div>
            <br />
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password *"
                value={formObject.confirmPassword?.value}
                id="confirmPassword"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </div>
            <br />
            <>
              {typeOfUser === "Candidate" && (
                <>
                  <div className="form-group">
                    <select
                      className="form-control form-select"
                      onChange={(e) => {
                        onChangeData(e);
                      }}
                      id="primarySkill"
                      value={formObject.primarySkill?.value}
                    >
                      {primarySkillList.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <br />
                  <div className="form-group">
                    <select
                      className="form-control form-select"
                      onChange={(e) => {
                        onChangeData(e);
                      }}
                      id="yearOfExperience"
                      value={formObject.yearOfExperience?.value}
                    >
                      <option value={""} default>
                        Select Experience
                      </option>
                      <option value={"0"}> {"<3"} </option>
                      <option value={"1"}>{"3-6"} </option>
                      <option value={"2"}> {">6"}</option>
                      {/* {yearOfExperience.map((item) => {
                        return <option value={item}>{item}</option>;
                      })} */}
                    </select>
                  </div>
                </>
              )}
            </>
            <div className="form-group form-switch"></div>
            <div className="form-group" style={{ paddingLeft: "30%" }}>
              <input
                type="submit"
                className="btnSubmit"
                value="Signup"
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>
            <br />
            <div className="form-group linktext">
              Already have and account?
              <div
                className="link pdl10"
                onClick={() => {
                  setFormType("login");
                }}
              >
                Login
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3" />
      </div>
    </div>
  );
};
export default Signup;
