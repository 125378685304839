import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showToast } from "../../util";
export default function ResourceSearchComponent({ softSkillList }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const initialState = {
    search: searchParams.get("search")
      ? JSON.parse(atob(searchParams.get("search")))
      : null,
  };

  const [selectedOption, setSelectedOption] = useState(
    initialState.search ? initialState.search : []
  );
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const handleOptionChange = (selectedList) => {
    setSelectedOption(selectedList);
  };
  const onSearch = (e) => {
    e.preventDefault();

    if (selectedOption.length === 0) {
      showToast({ type: "error", message: `Select least 1 skill set !` })
      return
    }

    navigate(`/available-resources?search=${btoa(JSON.stringify(selectedOption))}`);
  };
  return (
    <>
      <form className="pb-4 skill-search-form" onSubmit={onSearch}>
        <div className="input-group">
          {/* <input
            type="text"
            className="form-control"
            placeholder="Search for profiles"
            name="search"
            onChange={handleOptionChange}
            value={selectedOption.search}
          /> */}
          <Multiselect
            className="w-100"
            displayValue="label"
            selectedValues={selectedOption}
            onSelect={(selectedOption, selectedItem) => {
              setSelectedOption(selectedOption)
            }}
            onRemove={(selectedOption, removedItem) => {
              setSelectedOption(selectedOption)
            }}
            showCheckbox={true}
            hideSelectedList={false}
            isObject={true}
            options={softSkillList}
          />
          <button className="btn btn-primary" type="submit">
            <BsSearch />
          </button>
        </div>
      </form>
    </>
  );
}
