import React, { useState, useRef } from "react";
import "./EmployeeSatifactionSurvey.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsHandThumbsDown, BsHandThumbsUp } from "react-icons/bs";
import { showToast } from "../../util";
import { postEmployeeSatisfactionSurvey } from "../../networking/apiFunctions/employeeApiFunction";

export default function EmployeeSatisfactionSurvey() {
  let departmentSelected = useRef("");
  const userDetails = JSON.parse(localStorage.getItem("userValues"));
  const [isEmployeeHappy, setIsEmployeeHappy] = useState(null);
  const [comments, setComments] = useState("");
  const [commentsMandatory, makeCommentsMandatory] = useState(false);

  const functionsList = [
    "Onboarding",
    "IT Access",
    "Knowledge Transfer",
    "Other",
  ];

  const surveyQuestion = "Are you happy with your overall experience?";

  const submitData = () => {
    if (isEmployeeHappy === null) {
      showToast({ message: "Please select your response.", type: "warning" });
    } else if (isEmployeeHappy === false && !departmentSelected.current) {
      showToast({ message: "Please select a function.", type: "warning" });
    } else if (departmentSelected.current === "Other" && !comments) {
      showToast({ message: "Please provide some feedback.", type: "warning" });
    } else {
      const dataObject = {
        userId: userDetails.userId,
        employee_response: isEmployeeHappy ? "Satisfied" : "Dissatisfied",
        functions_selected: departmentSelected.current,
        comments: comments,
      };
      try {

        const result = postEmployeeSatisfactionSurvey(dataObject)
        showToast({ message: "Your response has been captured. Thank you!", type: "success", })
        departmentSelected.current = ""
        setIsEmployeeHappy(null)
        setComments("")

      } catch (e) {
        showToast({ message: "Oops!! Something went wrong.", type: "error" });
      }
    }
  };

  const getFunctionsList = (e) => {
    if (e.target.checked) {
      if (e.target.value === "Other") {
        makeCommentsMandatory(true);
      } else {
        makeCommentsMandatory(false);
      }
      departmentSelected.current = e.target.value;
    }
  };

  const updateFeedback = (e) => {
    setComments(e.target.value);
  };

  return (
    <div className="employee-satisfaction-survey pt-3 background-logo">
      <div className="psl-content" style={{ textAlign: "left" }}>
        <h5 className="card-title">Employee Service</h5>
        <br />
        <span>Let us know what you think!</span>
        <div id="survey" className="pt-4" style={{ textAlign: "left" }}>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <span>1.</span>&nbsp;&nbsp;
            <span>
              {surveyQuestion}
              &nbsp;
              <span
                className="text-danger fw-bolder"
                style={{ fontSize: "16px" }}
              >
                *
              </span>
            </span>
          </div>
          {/* <SmileyEmojiRating
            setSatisfactionScore={(score) => setSatisfactionScore(score)}
            satisfactionScore={satisfactionScore}
          /> */}
          <div className="d-flex flex-nowrap px-4 pt-3">
            <div className="row gx-5">
              <div className="form-check col-6">
                <input
                  hidden
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  aria-label="Not Happy"
                  value={!isEmployeeHappy}
                />
                <BsHandThumbsUp
                  role="button"
                  color={`${isEmployeeHappy === true ? "green" : "darkgrey"}`}
                  className="like"
                  size={30}
                  title="Happy"
                  onClick={() => setIsEmployeeHappy(true)}
                />
                {/* <br />
                <label className="action-style">Happy</label> */}
              </div>
              <div className="form-check col-6">
                <input
                  hidden
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  aria-label="Not Happy"
                  value={!isEmployeeHappy}
                />
                <BsHandThumbsDown
                  role="button"
                  className="dislike"
                  color={`${isEmployeeHappy === false ? "red" : "darkgrey"}`}
                  size={30}
                  title="Not Happy"
                  onClick={() => setIsEmployeeHappy(false)}
                />
                {/* <br />
                <label className="action-style">Not Happy</label> */}
              </div>
            </div>
          </div>
          {isEmployeeHappy === false ? (
            <>
              <div className="pt-4">
                <span>2.</span>&nbsp;&nbsp;
                <span>
                  Please select from a list of functions where your experience
                  could be improved:
                </span>
                &nbsp;
                <span
                  className="text-danger fw-bolder"
                  style={{ fontSize: "16px" }}
                >
                  *
                </span>
                <div
                  className="pt-3"
                  style={{
                    display: "grid",
                    gridGap: "5px",
                    gridTemplateColumns: "repeat(auto-fill, minmax(98px, 1fr)",
                    whiteSpace: "noWrap",
                  }}
                >
                  {functionsList.map((elem, i) => {
                    return (
                      <span style={{ marginRight: "25px" }}>
                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckChecked-${i}`}
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            disabled={isEmployeeHappy}
                            className="form-check-input"
                            type="radio"
                            name="functions_list"
                            value={functionsList[i]}
                            id={`flexCheckChecked-${i}`}
                            style={{ marginRight: "8px" }}
                            onChange={getFunctionsList}
                          />
                          {elem}
                        </label>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="pt-4 pb-1">
                <div className="form-group">
                  <span>
                    <span>3.</span>&nbsp;&nbsp;
                    <span>{`${commentsMandatory
                      ? `Please provide additional feedback:`
                      : `Please provide additional feedback, if any:`
                      }`}</span>
                  </span>
                  &nbsp;
                  {commentsMandatory ? (
                    <span
                      className="text-danger fw-bolder"
                      style={{ fontSize: "16px" }}
                    >
                      *
                    </span>
                  ) : null}
                  <textarea
                    className="form-control mt-2"
                    id="textArea"
                    rows="2"
                    value={comments}
                    onChange={updateFeedback}
                  ></textarea>
                </div>
              </div>
            </>
          ) : null}
          <hr />
          {
            <div className="pt-1 text" style={{ textAlign: "left" }}>
              <button
                className="btn btn-primary btn-md"
                onClick={() => submitData()}
              >
                Submit
              </button>
            </div>
          }
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
