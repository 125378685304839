import React from "react";
import Login from "./Login";
import Signup from "./Signup";

const LoginValidator = (props) => {
  const [formType, setFormType] = React.useState("login");
  return (
    <>
      {formType === "login" ? (
         <Login setFormType={setFormType} setIsUserLoggedIn={props.setIsUserLoggedIn}/>
      ) : (
        <Signup setFormType={setFormType} />
      )}
    </>
  );
};
export default LoginValidator;
