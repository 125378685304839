export const ErrorMessages = {
    "required": (entity) => `${entity} is required`,
    "minlength": (entity) => `${entity} name must be at least 2 characters long`,
    "maxlength": (entity) => `${entity} name must be at most 20 characters long`,
    "pattern": (entity) => `${entity} name must contain only letters`,
    "email": "Invalid email",
    "password": "XXXXXXX password",
    "confirmPassword": "XXXXXXXXX do not match",
    "phone": "Invalid phone number",
    "date": "Invalid date",
    "time": "Invalid time",
    "number": "Invalid number",
    "url": "Invalid URL",
    "numberRange": (entity) => (entity.minValue !== entity.maxValue) ? `${entity.entityName} must be between ${entity.minValue} and ${entity.maxValue}` : entity.additionalMessage,
    "minLength": (entity) => `${entity} must be at least ${entity.minLength} characters long`,
    "maxLength": (entity) => `${entity} must be at most ${entity.maxLength} characters long`,
    "minItems": (entity) => `${entity} must contain at least ${entity.minItems}`,
    "noSpecialCharacters": (entity) => `${entity.entityName} must contain only letters and numbers`,
    "minandmax": (entity) => `${entity.entityName} must be at least ${entity.minLength} characters long and at most ${entity.maxLength} characters long`,
}