import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppLayoutContext } from "../../contexts/LayoutContext"
import { useDropzone } from 'react-dropzone'
import './ResumeUpload.css'
import { showToast } from "../../util"
import { MultiDropdownComponent } from "./multiDropdownComponent"
import ResumeUploadValidation from "./validateResumeUpload"
import { postCandidateResumeUpload } from "../../networking/apiFunctions/candidateApiFunctions"
import { AxiosError } from "axios"
import { getLocationList, getRoleList, getSoftSkillsList } from "../../networking/apiFunctions/serviceApiFunctions"




const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/resume-upload",
    title: "Resume Upload",
    active: true,
  },
]

const skills = {
  'primarySkill': 'primarySkill',
  'secondarySkill': 'secondarySkill'
}

export default function ResumeDetails() {
  const userData = localStorage.getItem("userValues") ? JSON.parse(localStorage.getItem("userValues")) : {};
  const [userDetails] = useState(
    userData
  )

  const { updateBreadcrumbData, showOverlay, hideOverlay } = useAppLayoutContext()
  updateBreadcrumbData(breadcrumbs)


  const [attachedFile, setAttachedFile] = useState(null)
  const [numberOfYearExperience, setNumberOfYearExperience] = useState(null)
  const [email, setEmail] = useState(userDetails.userEmail && userDetails.userType === 'Candidate' ? userDetails.userEmail : null)
  const [profileSummary, setProfileSummary] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [roleList, setRoleList] = useState([])
  const [primarySkillList, setPrimarySkillList] = useState([])
  const [secondarySkillList, setSecondarySkillList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [softSkillList, setSoftSkillList] = useState([])


  const initialState = {
    search: searchParams.get("search")
      ? JSON.parse(atob(searchParams.get("search")))
      : null,
  }
  const [selectedLocationOption, setSelectedLocationOption] = useState(initialState.search ? initialState.search : [])
  const [selectedRoleOption, setSelectedRoleOption] = useState(initialState.search ? initialState.search : [])
  const [selectedPrimarySkillOption, setSelectedPrimarySkillOption] = useState(initialState.search ? initialState.search : [])
  const [selectedSecondarySkillOption, setSelectedSecondarySkillOption] = useState(initialState.search ? initialState.search : [])

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => handleUpload(files),
    onDropRejected: () => { },
    multiple: false
  })

  useEffect(() => {
    getInitialDataForResumeUpload()
  }, [])

  const getInitialDataForResumeUpload = async () => {
    showOverlay()
    try {
      if (isLoading) { return }
      setIsLoading(true)


      const roleList = await getRoleList()
      const softSkillList = await getSoftSkillsList()
      const locationList = await getLocationList()

      setIsLoading(false)
      setSoftSkillList(softSkillList)

      const resultSkillList = formatSkillsList(softSkillList)
      setPrimarySkillList(resultSkillList)
      setSecondarySkillList(resultSkillList)

      const resultRoleList = formatRoleList(roleList)
      setRoleList(resultRoleList)

      setLocationList(locationList)


    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setIsLoading(false)
        console.log(err);
        console.error("Fetch location, softskill and role list failed", err);
        showToast({ type: "error", message: "Fetch location, softskill and role list failed." })

      }
    } finally {
      hideOverlay()
    }
  }

  /**
   * Function responsible to format softSkills List as required by dropDown list
   * @param {List} softSkillList
   * @returns 
   */
  const formatSkillsList = (softSkillList) => {
    return (
      softSkillList.filter((skillFilter) => {
        return skillFilter.status ? skillFilter : ''
      }).map((skillObj) => {
        const { softSkillId, softSkillDescription, status } = skillObj
        if (status) {
          return { value: softSkillId, label: softSkillDescription }
        }
      })
    )
  }

  /**
   * Function responsible to format role List as required by dropDown list
   * @param {List} roleListFromApi 
   * @returns 
   */
  const formatRoleList = (roleListFromApi) => {
    return (
      roleListFromApi.filter((roleFilter) => {
        return roleFilter.status ? roleFilter : ''
      }).map((roleObj) => {
        const { roleId, roleDescription, status } = roleObj
        if (status) {
          return { value: roleId, label: roleDescription }
        }
      })
    )
  }


  /**
   * hook to handle value swap between primary skill and secondary skill
   */
  useEffect(() => {
    if (softSkillList && softSkillList.length > 0) {
      validateSkillsBetweenPrimaryAndSecondary(skills.primarySkill)
    }
  }, [selectedPrimarySkillOption])

  useEffect(() => {
    if (softSkillList && softSkillList.length > 0) {
      validateSkillsBetweenPrimaryAndSecondary(skills.secondarySkill)
    }
  }, [selectedSecondarySkillOption])

  /**
   * Reuseable function responsible to validate skill between primary and secondary
   * @param {string} skilltype 
   * @returns null
   */
  const validateSkillsBetweenPrimaryAndSecondary = (skilltype) => {
    const formatedSkillList = formatSkillsList(softSkillList)
    if (skilltype === skills.primarySkill && selectedPrimarySkillOption.length > 0) {
      const result = updateSkills(selectedPrimarySkillOption, formatedSkillList)
      setSecondarySkillList(result)
      return
    }

    if (skilltype === skills.secondarySkill && selectedSecondarySkillOption.length > 0) {
      const result = updateSkills(selectedSecondarySkillOption, formatedSkillList)
      setPrimarySkillList(result)
      return
    }

    skilltype == skills.primarySkill ? setSecondarySkillList(formatedSkillList) : setPrimarySkillList(formatedSkillList)

  }

  /**
   * Reuseable function to remove / update skills between primary and secondary
   * @param {List} upatedSkillList 
   * @param {List} rawSkillList 
   * @returns List
   */
  const updateSkills = (upatedSkillList, rawSkillList) => {
    const skillResult = [...rawSkillList]
    upatedSkillList.map((skillObj) => {
      const { value } = skillObj
      const index = skillResult.findIndex(obj => obj.value === value)
      skillResult.splice(index, 1)
    })
    return skillResult
  }

  /**
   * Function responsible to create element to display resume upload status
   * @returns Element
   */
  const renderAttachedFilePreview = () => {
    return <div className="fw-bold text-center">uploaded resume... {attachedFile?.name}</div>
  }

  /**
   * Input values handlers
   * @param {Number | string } value 
   * @returns 
   */
  const experienceInputHandler = (value) => setNumberOfYearExperience(value)
  const emailInputHandler = (value) => setEmail(value)
  const profileSummaryInputHandler = (value) => setProfileSummary(value)

  /**
   * Validate and handle file
   * @param {files} files 
   * @returns 
   */
  const handleUpload = (files) => {
    const file = files[0]
    const validateResult = ResumeUploadValidation.validateUploadFile(file)
    if (!validateResult) {
      showToast({ type: "error", message: 'Upload files allowed docx, doc, pdf and pptx' })
      return
    }
    setAttachedFile(file)
  }


  /**
   * Function to validate input fields and submit data
   * @returns 
   */
  const validateAndSubmitForm = () => {
    const { status, message } = ResumeUploadValidation.validateUploadForm(attachedFile, numberOfYearExperience, email,
      profileSummary, selectedLocationOption, selectedRoleOption, selectedPrimarySkillOption,
      selectedSecondarySkillOption)

    if (!status) {
      showToast({ type: "error", message: message })
      return
    }
    //Post data API call here
    const locationValue = selectedLocationOption.map((location) => location.label).toString()
    const rolesValue = selectedRoleOption.map((role) => role.label).toString()
    const primarySkillValues = selectedPrimarySkillOption.map((skill) => skill.label).toString()
    const secondarySkillValues = selectedSecondarySkillOption.map((skill) => skill.label).toString()

    const formData = new FormData()
    formData.append("email", email)
    formData.append("primarySkill", primarySkillValues)
    formData.append("secondarySkill", secondarySkillValues)
    formData.append("summary", profileSummary)
    formData.append("experience", numberOfYearExperience)
    formData.append("location", locationValue)
    formData.append("lastRolePlayed", rolesValue)
    formData.append("file", attachedFile)

    setIsLoading(true)
    submitCandidateDetailsApiCall(formData)
  }

  /**
   * Function responsible to upload candidate profile data
   * @param {form data} payload 
   */
  const submitCandidateDetailsApiCall = async (payload) => {
    showOverlay()
    try {
      if (isLoading) { return }
      const response = await postCandidateResumeUpload(payload)
      setIsLoading(false)
      showToast({ type: "success", message: response })
      clearFormdata()

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setIsLoading(false)
        console.log(err);
        console.error("Resume upload and details failed:", err);
        showToast({ type: "error", message: "Oops!! Something went wrong." })
      }
    } finally { hideOverlay() }

  }

  /**
   * Function responsible to clear data
   */
  const clearFormdata = () => {
    setEmail('')
    setSelectedPrimarySkillOption([])
    setSelectedSecondarySkillOption([])
    setSelectedLocationOption([])
    setSelectedRoleOption([])
    setProfileSummary('')
    setNumberOfYearExperience(0)
    setAttachedFile(null)
  }


  return (
    <>
      <h4 className="items-center d-flex my-4 justify-content-center">
        Profile Details and Upload Resume
      </h4>

      <div className="container mt-5">

        <div className="row">
          <div className="col-md-5">

            <div className="fileUploadDiv uploadDivBg" {...getRootProps()}>
              <input type={'file'} {...getInputProps()} />

              <div className="alignItemsForUploadDiv">
                <img src={"assets/images/svgs/File.svg"} alt="Drop File" />

                <h4> Drag and drop resume here </h4>
                <h4> or </h4>
                <h4> click to select resume </h4>
                <p> Supported file format pdf, docx, doc, pptx </p>
                {attachedFile && renderAttachedFilePreview()}
              </div>


            </div>
            <div className="align-items-end d-flex my-4 justify-content-center w-100">
              <button className="btn btn-primary w-50" onClick={validateAndSubmitForm}>
                Submit
              </button>
            </div>

          </div>

          <div className="col-md-7">

            <div className="w-100 mb-3">
              <MultiDropdownComponent selectedOption={selectedLocationOption}
                setSelectedOption={setSelectedLocationOption} optionsList={locationList} placeholder='Select Location *' isSingleSelect={true} />
            </div>

            <div className="w-100 mb-3">
              <MultiDropdownComponent selectedOption={selectedRoleOption}
                setSelectedOption={setSelectedRoleOption} optionsList={roleList} placeholder='Select Role *' isSingleSelect={false} />
            </div>

            <div className="w-100 mb-3">
              <MultiDropdownComponent selectedOption={selectedPrimarySkillOption}
                setSelectedOption={setSelectedPrimarySkillOption} optionsList={primarySkillList} placeholder='Select primary Skills *' isSingleSelect={false} />
            </div>

            <div className="w-100 mb-3">
              <MultiDropdownComponent selectedOption={selectedSecondarySkillOption}
                setSelectedOption={setSelectedSecondarySkillOption} optionsList={secondarySkillList} placeholder='Select secondary Skills *' isSingleSelect={false} />
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <input
                  type="number"
                  placeholder="Enter experience *"
                  alt="experience"
                  keyboardType="numeric"
                  onChange={(event) => experienceInputHandler
                    (event.target.value)}
                  value={numberOfYearExperience}
                  className="w-100 py-2 px-1 border shadow-none rounded-1 bg-transparent"
                />

              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Enter email address *"
                  alt="Enter email address"
                  disabled={userDetails.userEmail && userDetails.userType === 'Candidate' ? true : false}
                  onChange={(event) => emailInputHandler(event.target.value)}
                  value={email}
                  className="w-100 py-2 px-1 border shadow-none rounded-1 bg-transparent"
                />
              </div>

            </div>

            <div>
              <textarea
                id='reumeUploadTextArea'
                name="profile Summary *"
                placeholder="Enter profile summary maximum 300 characters *"
                rows={4}
                cols={40}
                className="w-100 py-2 px-1 border shadow-none rounded-1 bg-transparent"
                value={profileSummary}
                onChange={(event) => profileSummaryInputHandler(event.target.value)}
                maxLength={300}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}