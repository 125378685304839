import { useEffect, useState } from "react";
import ResourceSearchComponent from "../ResourceSearchComponent";
import "./ClientDashboard.css";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import { getSoftSkillsList } from "../../networking/apiFunctions/serviceApiFunctions";
import { AxiosError } from "axios";

export default function ClientDashboard() {

  const [softSkillList, setSoftSkillList] = useState([])
  const { showOverlay, hideOverlay } = useAppLayoutContext();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    handleFetchSoftSkills()
  }, [])

  const handleFetchSoftSkills = async () => {
    if (isLoading) { return }
    try {
      setIsLoading(true)
      showOverlay()
      const result = await getSoftSkillsList()
      const resultList = formatSkillsList(result)
      setSoftSkillList(resultList)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err);
        showToast({ type: "error", message: "Failed to fetch softSkill list." })
      }
    } finally {
      setIsLoading(false)
      hideOverlay()
    }
  }

  /**
   * Function responsible to format softSkills List as required by dropDown list
   * @param {List} softSkillListFromApi 
   * @returns 
   */
  const formatSkillsList = (softSkillListFromApi) => {
    return (
      softSkillListFromApi.filter((skillFilter) => {
        return skillFilter.status ? skillFilter : ''
      }).map((skillObj) => {
        const { softSkillId, softSkillDescription, status } = skillObj
        if (status) {
          return { value: softSkillId, label: softSkillDescription }
        }
      })
    )
  }


  return (
    <>
      <div
        className="container mt-4 mb-4 client-dashboard-hero"
        style={{
          backgroundImage: "url(./assets/images/marketplace-hero.png)",
          backgroundColor: "#485363",
        }}
      >
        <div className="row g-4">
          <div className="col-12 col-md-6">
            <div className="py-4">
              <p>Start your search for skilled professionals here</p>
              <ResourceSearchComponent softSkillList={softSkillList} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-4">
          {/* {CLIENT_DASHBOARD_DATA.map((data, index) => {
            return (
              <div className="col-12 col-sm-6 col-md-4" key={index}>
                <CardComponent {...data} contentAlign="center" />
              </div>
            );
          })} */}
          <div className="card section-background">
            <div className="p-3 col-lg-12 col-md-12 col-sm-12">
              <h4 className="pb-3 text-white">About Persistent</h4>
              <div className="row row-cols-1 row-cols-md-2">
                <div className="col-lg-3 col-md-6">
                  <div className="h-100">
                    <div className="h-50">
                      <div className="card p-0 h-100 border-0 section-background">
                        <p className="card-text text-light">
                          33+ years of leadership in software engineering and
                          digital transformation.
                        </p>
                      </div>
                    </div>
                    <div className="h-50">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">$1,036M</h4>
                          <p className="card-text text-body-secondary">
                            FY23 Annual Revenue
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="h-100">
                    <div className="h-50">
                      <div className="card p-0 h-100 border-0 section-background">
                        <div className="">
                          <p className="card-text text-light">
                            Highest customer experience scores of any company in
                            IT industry, rated by ISG.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="h-50">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">350+</h4>
                          <p className="card-text text-body-secondary">
                            Services Clients Annually
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="h-100">
                    <div className="h-50">
                      <div className="card p-0 h-100 border-0 section-background">
                        <div className="">
                          <p className="card-text text-light">
                            Boutique mindset focused on enterprise clients
                            moving their digital presence to cloud.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="h-50">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">23,000+</h4>
                          <p className="card-text text-body-secondary">
                            Industry & Tech Experts
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="h-100">
                    <div className="h-50">
                      <div className="card p-0 h-100 border-0 section-background">
                        <div className="">
                          <p className="card-text text-light">
                            Diverse, open and innovative business partner
                            ecosystem for maximum adaptability.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="h-50">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">21</h4>
                          <p className="card-text text-body-secondary">
                            Countries
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* container 2 */}
      <div className="container">
        <div className="row g-4">
          <div className="card section-background">
            <div className="p-3 pb-4 col-lg-12 col-md-12 col-sm-12">
              <h4 className="pb-3 text-white">What We Do</h4>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                <div className="col-lg-4 col-md-6">
                  <div className="h-100">
                    <div className="card p-0 h-100 border-0 section-background">
                      <p className="card-text text-light">
                        Digital Strategy & Design
                        <br />
                        Software Product Engineering
                        <br />
                        CX Transformation
                        <br />
                        Cloud & Infrastructure
                        <br />
                        Intelligent Automation
                        <br />
                        Enterprise IT Security
                        <br />
                        Data & Analytics
                        <br />
                        Application Development & Management
                        <br />
                        Enterprise Integration
                        <br />
                        Generative AI
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div
                    className="card h-100"
                    style={{ backgroundColor: "turquoise" }}
                  >
                    <div className="pt-5 card-body text-center">
                      <img src={"assets/images/svgs/BFSI.svg"} alt="BFSI" />
                      <p
                        className="pt-3 card-text text-white"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span>Banking</span>
                        <span>
                          Financial <br />
                          Services
                        </span>
                        <span>Insurance</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div
                    className="card h-100"
                    style={{ backgroundColor: "skyblue" }}
                  >
                    <div className="pt-5 card-body text-center">
                      <img
                        src={"assets/images/svgs/Healthcare.svg"}
                        alt="BFSI"
                      />
                      <p
                        className="pt-3 card-text text-white"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span>Healthcare</span>
                        <span>Life Sciences</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div
                    className="card h-100"
                    style={{ backgroundColor: "#E4A598" }}
                  >
                    <div className="pt-5 card-body text-center">
                      <img src={"assets/images/svgs/Hitech.svg"} alt="Hitech" />
                      <p
                        className="pt-3 card-text text-white"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span>Software</span>
                        <span>Hi-tech</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div
                    className="card h-100"
                    style={{ backgroundColor: "lightgreen" }}
                  >
                    <div className="pt-5 card-body text-center">
                      <img
                        src={"assets/images/svgs/Telecom.svg"}
                        alt="Telecom"
                      />
                      <p
                        className="pt-3 card-text text-white"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span>Telecom</span>
                        <span>Media</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* container 3 */}
      <div className="container">
        <div className="row g-4">
          <div className="card bg-secondary">
            <div className="p-3 col-lg-12 col-md-12 col-sm-12">
              <h4 className="pb-3 text-white">
                Our Strategic Partner Ecosystem
              </h4>
              <div className="row p-3 row-cols-1 row-cols-md-2 g-4">
                <div className="col-lg-1 col-md-2 col-sm-3 col-xs-2">
                  <img src={"assets/images/svgs/AWS.svg"} alt="AWS" />
                </div>
                <div className="col-lg-1 col-md-2 col-sm-3 col-xs-1">
                  <img
                    src={"assets/images/svgs/SalesForce.svg"}
                    alt="SalesForce"
                  />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-4">
                  <img src={"assets/images/svgs/MS.svg"} alt="MS" />
                </div>
                <div className="col-lg-1 col-md-2 col-sm-3">
                  <img src={"assets/images/svgs/Saviynt.svg"} alt="Saviynt" />
                </div>
                <div
                  className="mx-1 col-lg-1 col-md-2 col-sm-3"
                  style={{ paddingLeft: "20px" }}
                >
                  <img src={"assets/images/svgs/Appian.svg"} alt="Appian" />
                </div>
                <div className="col-lg-1 col-md-2 col-sm-3">
                  <img src={"assets/images/svgs/IBM.svg"} alt="IBM" />
                </div>
                <div className="col-lg-1 col-md-2 col-sm-3">
                  <img src={"assets/images/svgs/Redhat.svg"} alt="Redhat" />
                </div>
                <div
                  className="mx-2 col-lg-1 col-md-2 col-sm-3"
                  style={{ paddingLeft: "25px" }}
                >
                  <img src={"assets/images/svgs/Mambu.svg"} alt="Mambu" />
                </div>
                <div
                  className="mx-2 col-lg-1 col-md-2 col-sm-3"
                  style={{ paddingLeft: "25px" }}
                >
                  <img
                    src={"assets/images/svgs/Snowflake.svg"}
                    alt="Snowflake"
                  />
                </div>
                <div
                  className="mx-2 col-lg-1 col-md-2 col-sm-3"
                  style={{ paddingLeft: "30px" }}
                >
                  <img
                    src={"assets/images/svgs/outsystems.svg"}
                    alt="outsystems"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
