import React from "react";
import { Link } from "react-router-dom";

export default function CardComponent({
  link,
  btnText,
  title,
  cardBody,
  contentAlign = "left",
  linkTarget,
}) {
  return (
    <div className="card h-100">
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <h5 className="card-title text-center mb-4">{title}</h5>
          {cardBody ? (
            <div className={`text-${contentAlign}`}>{cardBody}</div>
          ) : null}
        </div>

        {link ? (
          <div className="text-center">
            <Link
              className="btn btn-primary mt-3"
              id="Grammar"
              to={link}
              target={linkTarget}
            >
              {btnText}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
