import {
  ADMIN_DASHBOARD_DATA_REPORTS,
  ADMIN_DASHBOARD_DATA_MANAGE,
} from "../../data/AppData";
import CardComponent from "../cardComponent/CardComponent";
import OverLay from "./OverLay";

export default function AdminDashboard({ userDetails }) {
  return (
    <OverLay>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="my-4 text-center">
              {userDetails.userType} Dashboard
            </h4>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="mb-4 text-center text-capitalize">Reports</h4>
          </div>
        </div>
        <div className="row g-4">
          {ADMIN_DASHBOARD_DATA_REPORTS.map((data, index) => {
            return (
              <div className="col-12 col-sm-6 col-md-4" key={index}>
                <CardComponent {...data} contentAlign="center" />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12">
            <h4 className="my-4 text-center text-capitalize">Manage</h4>
          </div>
        </div>
        <div className="row g-4">
          {ADMIN_DASHBOARD_DATA_MANAGE.map((data, index) => {
            return (
              <div className="col-12 col-sm-6 col-md-4" key={index}>
                <CardComponent {...data} contentAlign="center" />
              </div>
            );
          })}
        </div>
      </div>
    </OverLay>
  );
}
