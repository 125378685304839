import { ErrorMessages } from "./ErrorMessages";
export const Validators = {
    validateEmail: (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    validatePassword: (password) => {
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(password);
    },
    validateEmptyString: (entity) => {
        console.log("🚀 ~ entity:", entity)
        if (!entity.entityValue) {
            return ErrorMessages.required(entity.entityName);
        } else return null;
        // return entity.entityValue === "" ? ErrorMessages.required(entity.entityName) : null;
    },
    validateStringLength: (entity) => {
        const { entityValue, minLength, maxLength } = entity;
        if (entityValue.length < minLength || entityValue.length > maxLength) {
            return ErrorMessages.minandmax(entity);
        } else return null;
        // return value.length >= minLength && value.length <= maxLength ? ErrorMessages.minandmax(entity) : null;
    },
    validateNumber: (value) => {
        return !isNaN(value);
    },
    validateNumberRange: (entity) => {
        const { entityValue, minValue, maxValue } = entity;
        console.log("🚀 ~ entity:", entity)
        if (!isNaN(entityValue) && entityValue >= minValue && entityValue <= maxValue) {
            return null;
        } else {
            return ErrorMessages.numberRange(entity);
        }
    },
    validateStringWithoutSpecialCharacters: (entity) => {
        var re = /^[a-zA-Z0-9 ]+$/;
        return re.test(entity.entityValue) ? null : ErrorMessages.noSpecialCharacters(entity);
    }
};
export default Validators;