import React, { memo, useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import MCQTimer from "../../components/QuizComponents/MCQTimer";
import { useNavigate } from "react-router-dom";
import QuizPagination from "../../components/QuizComponents/QuizPagination";
import ModalComponent from "../../components/ModalComponent";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import { showToast } from "../../util";
import { RecordRTCPromisesHandler } from "recordrtc";
import { getCandidateMcqQuestions, postCandidateAssessmentResult, putCandidateAssessmentVideoUpload } from "../../networking/apiFunctions/candidateApiFunctions";


const initialScore = {
  score: 0,
  unattemptedquestions: 0,
  totalQuestions: 0,
  attemptedquestions: 0,
};
const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/quiz",
    title: "Assessment",
    active: true,
  },
];

function Quize() {
  const { updateBreadcrumbData, showOverlay, hideOverlay } =
    useAppLayoutContext();
  const navigate = useNavigate();

  const [questionsData, setQuestionsData] = useState(null);
  const [testResultsSummary, setTestResultSummary] = useState(initialScore);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState({ selected: {} });
  const [loading, setLoading] = useState(false);
  const [assessmentAnswersData, setAssessmentAnswersData] = useState(null);
  const [loadingAssessment, setLoadingAssessment] = useState(true);
  const time = useRef(new Date());
  const disconnected = useRef(0);
  const recorderRef = useRef(null);
  const streamRef = useRef(null);
  const isAssessmentSubmitted = useRef(false);
  const [userDetails, setUserID] = useState(
    JSON.parse(localStorage.getItem("userValues"))
  );
  const [isVisible, setVisibility] = useState(true)

  const [showSubmitAssessmentModal, setShowSubmitAssessmentModal] =
    useState(false);
  const [showResults, setShowResults] = useState(false);

  const [isAutoSubmitForApiCall, setIsAutoSubmitForApiCall] = useState(false);
  const [autoAssessmentAnswersData, setAutoAssessmentAnswersData] = useState();

  updateBreadcrumbData(breadcrumbs);

  useEffect(() => {
    startVideoRecording();

    // Cleanup function to stop video recording when the component unmounts
    return () => {
      // stopVideoRecording();
    };
  }, []);
  const startVideoRecording = async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      recorderRef.current = new RecordRTCPromisesHandler(streamRef.current, {
        type: "video",
      });

      await recorderRef.current.startRecording();
    } catch (error) {
      console.error("Error starting video recording:", error);
    }
  };
  const stopVideoRecording = async () => {
    showOverlay();
    console.log("Stopping video recording...");
    try {
      if (recorderRef.current) {
        await recorderRef.current.stopRecording(url => {
          // this.video.src = url;
          recorderRef.current.destroy();
          recorderRef.current = null;
        });
        const blob = await recorderRef.current.getBlob();
        console.log("Video recording stopped successfully.");
        console.log(blob); // Check if the blob is generated

        recorderRef.current = null;
        handleStopStreamingTrack()

        // Save the Blob as a video file locally
        const videoURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = videoURL;
        a.download = "recorded-video.webm"; // Specify the filename and format
        a.click();
        window.URL.revokeObjectURL(videoURL);

        // Upload blob to S3
        const formData = new FormData();
        formData.append("userId", userDetails.userId);
        formData.append("file", new File([blob], `recorded-video-from-assessment-new-${userDetails.userId}-${Date.parse(new Date)}.webm`));

        setLoading(true)
        await putCandidateAssessmentVideoUpload(formData)
        showToast({ type: "success", message: `The video was uploaded successfully` });
        setLoading(false)
      }
    } catch (err) {
      console.error("Error stopping video recording:", err)
      if (err instanceof AxiosError || err instanceof Error) {
        setLoading(false)
        console.log(err)
        console.error("Video upload failed", err);
        showToast({ type: "error", message: "Video upload failed." })
      }
    } finally {
      hideOverlay()
    }
  };

  const handleStopStreamingTrack = () => {
    streamRef.current.getTracks().forEach((track) => {
      if (track.readyState == 'live') {
        track.stop();
      }
    })
  }


  useEffect(() => {
    if (!questionsData) {
      updateData();
    }
  }, []);

  // used hack for now to resolve repetation of alert used [1,3,5]
  const visibilityChange = async () => {
    if (isAssessmentSubmitted.current) {
      return
    }
    console.log("Visibility changed!");
    disconnected.current += 1

    if (disconnected.current === 1 || disconnected.current === 3) {
      alert(
        "Please do not try to open/switch tabs. The test will get auto-submitted."
      );
    }


    if (disconnected.current === 5) {
      console.log("Auto-submitting the test...");
      alert("You have been warned 3 times hence auto-submitting the test!");
      setVisibility(false)

      if (!questionsData || questionsData === null) {
        await updateData()
      }

      setIsAutoSubmitForApiCall(true)

    }
  };

  useEffect(() => {
    if (isAutoSubmitForApiCall && questionsData) {
      setIsAutoSubmitForApiCall(false)
      handleSubmitTest(true)
    }

  }, [isAutoSubmitForApiCall, questionsData])



  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChange)

    return () => { document.removeEventListener("visibilitychange", visibilityChange) }
  }, []);

  useEffect(() => {
    if (!isVisible) {
      document.removeEventListener("visibilitychange", visibilityChange)
    }
  }, [isVisible])


  const updateData = async () => {
    if (loading) { return }

    setLoading(true);
    showOverlay();
    try {
      const data = await getCandidateMcqQuestions(userDetails.userId)
      setLoading(false);
      setQuestionsData(data);
      setLoadingAssessment(false);

      time.current = new Date(time.current.setSeconds(
        time.current.getSeconds() + 60 * data.time
      ));

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err);
        setLoading(false);
        showToast({ message: "Oops!! Something went wrong.", type: "error" });
      }
    } finally {
      hideOverlay();
    }
  };
  const handleOptionChange = (option, id) => {
    setSelectedOption((currentState) => ({
      selected: { ...currentState.selected, [id]: option },
    }));
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const changeQuestion = (index) => {
    setCurrentQuestionIndex(index);
  };

  const calculateScore = (answers, autoSubmit) => {
    let score = 0,
      unattemptedquestions =
        questionsData.questions.length - Object.keys(answers).length;
    questionsData.questions.forEach((item, index) => {
      if (index + 1 in answers && item.answer === answers[index + 1]) {
        score += 1;
      }
    });

    setTestResultSummary({
      score,
      unattemptedquestions,
      totalQuestions: questionsData.questions.length,
      attemptedquestions: Object.keys(answers).length,
    });
  };

  const onTimerExpire = () => {
    if (!showResults) {
      handleSubmitTest(true);
    }
  };

  const handleSubmitTest = (autoSubmit) => {

    const keys = Object.keys(selectedOption.selected);
    const values = Object.values(selectedOption.selected);
    let selectedAnswers = {
      testType: questionsData.testType,
      answers: [],
      userId: userDetails.userId,
    };

    for (let i = 0; i < keys.length; i++) {
      const tpc = questionsData.questions.find((item) => {
        return item.questionId.toString() === keys[i];
      });
      selectedAnswers.answers.push({
        questionId: keys[i],
        selectedOption: values[i].split(")")[0],
      });
    }

    calculateScore(selectedOption.selected, autoSubmit);
    if (autoSubmit === true) {
      setAutoAssessmentAnswersData(selectedAnswers)
    } else {
      setShowSubmitAssessmentModal(true);
      setAssessmentAnswersData(selectedAnswers);
    }
  };

  useEffect(() => {
    if (autoAssessmentAnswersData) {
      submitAssessment(autoAssessmentAnswersData, true);
      setShowSubmitAssessmentModal(false);
    }

  }, [autoAssessmentAnswersData])

  const submitAssessment = async (selectedAnswers, autoSubmit) => {
    /*
     Do not delete this comment may required for feature
     if answer not selected then do not auto submit 

     for above feature just uncomment the code and all should work fine
     considering this edge case as crucial 

     *********************************************

    if (autoSubmit) {
      if (!autoAssessmentAnswersData || !autoAssessmentAnswersData.answers) {
        console.error("Assessment answers data is undefined or missing answers.");
        return;
      }
    } else {
      if (!assessmentAnswersData || !assessmentAnswersData.answers) {
        console.error("Assessment answers data is undefined or missing answers.");
        return;
      }
    } */

    if (autoSubmit) {
      if (!assessmentAnswersData || !assessmentAnswersData.answers) {
        console.error("Assessment answers data is undefined or missing answers.");
        return;
      }
    }


    const selectedAnswersLength = selectedAnswers.answers.length;
    if (loading) { return }

    showOverlay();
    try {
      setLoading(true)
      const data = await postCandidateAssessmentResult(selectedAnswers)

      setLoading(false)
      isAssessmentSubmitted.current = true
      localStorage.setItem("isCandidateAssessmentCompleted", true)
      setTestResultSummary({
        score: data.score,
        unattemptedquestions:
          questionsData.questions.length - selectedAnswersLength,
        totalQuestions: questionsData.questions.length,
        attemptedquestions: selectedAnswersLength,
        status: data.status,
        percentageScore: data.percentageScore,
      });
      await stopVideoRecording();

      setShowResults(true);
      setShowSubmitAssessmentModal(false);

    } catch (err) {
      // await stopVideoRecording();
      if (err instanceof AxiosError || err instanceof Error) {
        setLoading(false)
        console.log(err);
        console.error("Error stopping screen recording:", err);
        showToast({ type: "error", message: `Oops!! ${err}` });
      }
    } finally {
      hideOverlay();
    }
  };

  const onAssessmentModalSubmit = () => {
    submitAssessment(assessmentAnswersData);
    setShowSubmitAssessmentModal(false);
  };
  const onAssessmentModalClose = () => {
    setShowSubmitAssessmentModal(false);
  };


  return (
    <div>
      {loadingAssessment ? (
        <div className="container">
          <div className="row g-3 mb-4">
            <div className="col-12">
              <h4 className="text-center text-muted py-4">
                Loading Assessment...
              </h4>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h4 className="items-center d-flex my-4 justify-content-center">
            Skill Assessments
          </h4>
          {showResults ? (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="card text-center">
                    <div className="card-body">
                      <div className="card-title border-bottom mb-4 fw-bold">
                        <h5>Results Summary</h5>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 pb-4">
                          <h5 className="card-title text-center text-capitalize mb-4">
                            Your Score
                          </h5>
                          <div
                            className={
                              testResultsSummary.status === "pass"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            <h5 className="card-title text-center mb-2 fw-bold">
                              {testResultsSummary.percentageScore}%
                            </h5>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 pb-4">
                          <h5 className="card-title text-center text-capitalize mb-4">
                            Total Questions
                          </h5>
                          <h5 className="card-title text-center mb-4 fw-bold">
                            {testResultsSummary.totalQuestions}
                          </h5>
                        </div>
                        <div className="col-12 col-md-4 pb-4">
                          <h5 className="card-title text-center text-capitalize mb-4">
                            Unattempted Questions
                          </h5>
                          <h5 className="card-title text-center mb-4 text-danger fw-bold">
                            {testResultsSummary.unattemptedquestions}
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="fw-bold text-center">
                            {testResultsSummary &&
                              testResultsSummary.status === "pass" ? (
                              <span className="text-success">
                                *Passed!!: You have cleared the assessments and
                                shall be notified of next steps.
                              </span>
                            ) : (
                              <span className="text-danger">
                                *Failed. Sorry you have not cleared your
                                assessments.
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row g-3 mb-4">
                <div className="col-12">
                  <QuizPagination
                    pagesData={questionsData?.questions}
                    currentQuestionIndex={currentQuestionIndex}
                    onClick={(page) => changeQuestion(page)}
                    selectedOption={selectedOption.selected}
                  />
                </div>
              </div>
              <div className="row g-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="card-title card-titles">
                            Time Left :{" "}
                            <MCQTimer
                              expiryTimestamp={time.current}
                              onTimerExpire={onTimerExpire}
                            />
                          </h5>
                          <p className="text-danger fs-6">
                            *Note: Test will be auto submitted once the timer
                            expires
                          </p>
                          <p className="text-danger fs-6">
                            Tab/Window Switching not allowed, test will be auto
                            submitted after 3 warnings.
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-4 card-titles">
                              Unattempted :{" "}
                              <span className="text-danger fw-bold">
                                {questionsData?.questions.length -
                                  Object.keys(selectedOption?.selected).length}
                              </span>
                            </div>
                            <div className="col-md-4 card-titles">
                              Attempted :{" "}
                              <span className="text-success fw-bold">
                                {Object.keys(selectedOption?.selected).length}
                              </span>
                            </div>
                            <div className="col-md-4 text-end">
                              <button
                                type="button"
                                onClick={handleSubmitTest}
                                className="btn btn-primary"
                              >
                                Submit Test
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {questionsData?.questions.length > 0 && (
                    <div className="d-flex justify-content-center flex-column py-3">
                      <p data-testid="question">
                        {currentQuestionIndex + 1}.{" "}
                        {
                          questionsData?.questions[currentQuestionIndex]
                            .question
                        }
                      </p>
                      {questionsData?.questions[
                        currentQuestionIndex
                      ].options.map((option, index) => (
                        <div className="p-2" key={index}>
                          <input
                            type="radio"
                            id={option}
                            name={option}
                            value={option}
                            checked={
                              selectedOption?.selected[
                              questionsData?.questions[currentQuestionIndex]
                                .questionId
                              ] == option
                            }
                            onChange={() =>
                              handleOptionChange(
                                option,
                                questionsData?.questions[currentQuestionIndex]
                                  .questionId
                              )
                            }
                            data-testid="option-1"
                          />
                          <label className="px-2" htmlFor={option}>
                            {option}
                          </label>
                        </div>
                      ))}

                      <div className="items-center d-flex my-4 justify-content-end">
                        {currentQuestionIndex > 0 && (
                          <button
                            className="btn btn-secondary mt-4 prev-btn"
                            onClick={handlePrevQuestion}
                            data-testid="prev"
                          >
                            Previous
                          </button>
                        )}
                        {currentQuestionIndex <
                          questionsData.questions.length - 1 && (
                            <button
                              className="btn btn-primary mt-4 ms-2"
                              onClick={handleNextQuestion}
                              data-testid="next"
                            >
                              Next
                            </button>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {showSubmitAssessmentModal ? (
            <ModalComponent
              title={"Submit Assessment"}
              onCloseClick={onAssessmentModalClose}
              showFooter={true}
              modalId={"assessmentSubmitModal"}
              modalBody={
                <div className="container text-center">
                  <div className="row">
                    <div className="col-12 col-md-4 pb-4">
                      <h5 className="card-title text-center text-capitalize mb-4">
                        Total Questions
                      </h5>
                      <span className="fw-bold">
                        {questionsData.questions.length}
                      </span>
                    </div>
                    <div className="col-12 col-md-4 pb-4">
                      <h5 className="card-title text-center text-capitalize mb-4">
                        Attempted Questions
                      </h5>
                      <span className="fw-bold text-success">
                        {assessmentAnswersData &&
                          assessmentAnswersData.answers.length
                          ? assessmentAnswersData.answers.length
                          : 0}
                      </span>
                    </div>
                    <div className="col-12 col-md-4 pb-4">
                      <h5 className="card-title text-center text-capitalize mb-4">
                        Unattempted Questions
                      </h5>
                      <span
                        className={`${parseInt(
                          questionsData.questions.length -
                          assessmentAnswersData.answers.length
                        ) > 0
                          ? "text-danger fw-bold"
                          : ""
                          }`}
                      >
                        {questionsData.questions.length -
                          assessmentAnswersData.answers.length}
                      </span>
                    </div>
                  </div>
                </div>
              }
              footer={
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onAssessmentModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onAssessmentModalSubmit}
                  >
                    Submit Assessment
                  </button>
                </>
              }
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default memo(Quize);
