export const CANDIDATE_DASHBOARD_DATA = [
  {
    link: "/quiz-home",
    title: "Skill Assessments",
    cardBody:
      "Take an Skill Assessment (MCQs) to test your skills in your respective tech stack",
    btnText: "Know More",
  },
  {
    link: "/skill-gap-analytics",
    title: "Skill Gap Analytics",
    cardBody:
      "Check your identified skill gaps after completion of assessments",
    btnText: "Know More",
  },
  {
    link: "/recommendations",
    title: "Training Recommendations",
    cardBody:
      "Upskill yourself by undergoing recommended trainings and tutorials",
    btnText: "Know More",
  },
  {
    link: "/candidate-survey",
    title: "Candidate Survey",
    cardBody: "Take a survey to let us know your experience with our processes",
    btnText: "Take Survey",
  },
  {
    link: "/resume-upload",
    title: "Resume Upload",
    cardBody: "Let us know your career experience and profile details",
    btnText: "Know More",
  },
];

export const EMPLOYEE_DASHBOARD_DATA = [
  {
    link: "/employee-profile",
    title: "My Profile",
    cardBody: "View your profile details",
    btnText: "Go to My Profile",
  },
  {
    link: "#",
    title: "Employee Team Structure",
    cardBody: "Know Your Team Structure",
    btnText: "Know More",
  },
  {
    link: "#",
    title: "Training Recommendations",
    cardBody: "Skill Development",
    btnText: "Know More",
  },
  {
    link: "#",
    title: "Career Planning",
    cardBody: "Career Planning",
    btnText: "Know More",
  },
];

export const CLIENT_DASHBOARD_DATA = [
  {
    link: "/available-resources?search=Java Microservices",
    title: "Java Microservices",
    cardBody: "40",
    btnText: "View Profiles",
  },
  {
    link: "/available-resources?search=React",
    title: "UI-React",
    cardBody: "25",
    btnText: "View Profiles",
  },
  {
    link: "/available-resources?search=Quality Assurance",
    title: "Quality Assurance",
    cardBody: "30",
    btnText: "View Profiles",
  },
  {
    link: "/available-resources?search=Cloud Engineering",
    title: "Cloud Engineering",
    cardBody: "10",
    btnText: "View Profiles",
  },
];

export const ADMIN_DASHBOARD_DATA_REPORTS = [
  {
    link: "/candidate-assessment-report",
    title: "Candidate Report",
    cardBody: "View Candidate Report",
    btnText: "Know More",
  },
  {
    link: "/employee-survey-report",
    title: "Employee Service Report",
    cardBody: "View Employees Service Report",
    btnText: "Know More",
  },
  {
    link: "#",
    title: "Employee Details Report",
    cardBody: "View Employees Details Report",
    btnText: "Know More",
  },
  {
    link: "/resume-uploaded-candidate-report",
    title: "Resume Uploaded Candidate Report",
    cardBody: "View Candidate Report",
    btnText: "Know More",
  },
];

export const ADMIN_DASHBOARD_DATA_MANAGE = [
  {
    link: "/resume-upload",
    title: "Manage Candidate",
    cardBody: "Manage candidate resume and profile details",
    btnText: "Know More",
  },
  {
    link: "/test-management",
    title: "Assessment Management",
    cardBody: "Create and Manage Assessments",
    btnText: "Know More",
  },
  {
    link: "#",
    title: "Post-Onboarding",
    cardBody: "Post-Onboarding",
    btnText: "Know More",
  },
  {
    link: "/create-user",
    title: "Create User",
    cardBody: "Manager User Access",
    btnText: "Know More",
  },
  {
    link: "/manage-skills",
    title: "Manage Skills",
    cardBody: "Manage Skills",
    btnText: "Know More",
  },
  {
    link: "/manage-roles",
    title: "Manage Roles",
    cardBody: "Manage Roles",
    btnText: "Know More",
  },
];
