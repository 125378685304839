import { toast } from "react-toastify"
import { postCognitoSignin } from "./networking/apiFunctions/serviceApiFunctions";

export const showToast = ({ type, message }) => {
  toast(message, { type: type, position: "top-right" });
};

// Below function responsible to get JWT token from cognito
export const doCognitoSignIn = async (resp) => {
  try {
    let json = {
      skill: resp.skill,
      email: resp.userEmail,
      username: resp.userName,
      password: btoa(resp.userPassword),
      userType: resp.userType,
      yearOfExperience: JSON.stringify(resp.yearOfExperience)
    }

    const result = await postCognitoSignin(json)
    let jwt = JSON.stringify(result.jwtToken)
    localStorage.setItem("jwt", jwt.replaceAll('"', ''))
    return true
  } catch (err) {
    throw new Error(err)
  }
}
