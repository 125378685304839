import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import { getCandidateMcqSummary } from "../../networking/apiFunctions/candidateApiFunctions";
import { showToast } from "../../util";
import { AxiosError } from "axios";

const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/quiz-home",
    title: "Skill Assessment",
    active: true,
  },
];
export default function QuizHome() {
  const navigate = useNavigate();
  const [questionnaires, setQuestionnaires] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const { updateBreadcrumbData } = useAppLayoutContext();
  const { showOverlay, hideOverlay } = useAppLayoutContext()
  const [userDetails, setUserID] = useState(
    JSON.parse(localStorage.getItem("userValues"))
  );
  updateBreadcrumbData(breadcrumbs);

  useEffect(() => {
    getMcqSummary()
  }, [])

  const getMcqSummary = async () => {
    showOverlay()
    try {
      if (isLoading) { return }
      setIsLoading(true)
      const mcqResult = await getCandidateMcqSummary(userDetails.userId)
      setIsLoading(false)
      setQuestionnaires(mcqResult)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setIsLoading(false)
        console.log(err);
        console.error("Fetch mcq summary failed", err);
        showToast({ type: "error", message: "Fetch mcq summary failed." })
      }
    } finally { hideOverlay() }
  }

  const updateSelectedBlock = (testId, testType) => {
    navigate(`/quiz`);
  };


  return (
    <>
      <h4 className="items-center d-flex my-4 justify-content-center">
        Skill Assessments
      </h4>

      <div className="container">
        <div className="row">
          {questionnaires ?
            <QuestionBockcard
              key={0}
              questionBlock={questionnaires}
              updateSelectedBlock={updateSelectedBlock}
            />
            : null
          }
        </div>
        <br />
        <div className="row">
          <div className="card" style={{ padding: "inherit" }}>
            <h5 className="card-header">Details</h5>
            <div className="card-body">
              <h5 className="card-title">Rules</h5>
              <p>1. There are no reattempts.</p>
              <p className="card-text">
                2. Try sitting in an isolated room with no disturbance of Human
                or Technical in nature.
              </p>
              <p>3. No Bio Breaks will be allowed.</p>
              <p>
                4. Mobile Phones should be switched off and kept away / out of
                reach.
              </p>
              <p style={{ color: "red", fontWeight: "bold" }}>
                5. Dont Swich tabs or windows, Test will be auto submitted after
                3 instances of Tab/Window Switch
              </p>
              <p style={{ color: "red", fontWeight: "bold" }}>
                6. Allow access to camera and michrophone on start the test.
              </p>
              <br />
              <h5 className="card-title">Important Note</h5>
              <p>1. My answers to questions will be my own work</p>
              <p>
                2. I will not copy/memorize the test content, questions/answers
                from anyone else & pass them on to future test-takers
              </p>
              <p>
                3. I will not make my solutions to assignments, quizzes or exams
                available to anyone else
              </p>
              <p>4. I attest that all the answers in the test will be my own</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const QuestionBockcard = (props) => {
  const { id, testType, numberOfQuestions, timeLimit, testScore } =
    props.questionBlock;
  const { updateSelectedBlock } = props;

  const attemptQuiz = (e) => {
    e.preventDefault();
    updateSelectedBlock(id, testType);
  };

  return (
    <>
      <div className="col col-sm-2 col-md-4">
        <div className="card h-100">
          <div className="card-body d-flex flex-column">
            <div className="flex-grow-1">
              <h5 className="card-title text-center text-capitalize mb-4">
                {testType}
              </h5>

              <h6 className="card-subtitle mb-2 text-muted pb-2">
                Questions : {numberOfQuestions}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted pb-2">
                Time : {timeLimit} Minutes
              </h6>
              <h6 className="card-subtitle mb-2 text-muted pb-3">
                Attempt left : 1
              </h6>
              {/* {testScore != null ? (
                <h6 className="card-subtitle mb-2 text-muted pb-2">
                  Your Score : {testScore}/{numberOfQuestions}
                </h6>
              ) : (
                <h6 className="card-subtitle mb-2 text-muted pb-3">
                  Attempt left : 1
                </h6>
              )} */}
            </div>
            <div className="text-center">
              <button
                className="btn btn-primary mt-3"
                id={testType}
                disabled={testScore != null}
                onClick={attemptQuiz}
              >
                Start Test
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
