import React from 'react'
import { Navigate } from 'react-router-dom'
// import Cookies from 'js-cookie';

const types = {
    Candidate: 'Candidate',
    Admin: 'Admin',
    Client: 'Client'
}
const routeMappingList = {}
routeMappingList[types.Candidate] = ['/quiz-home', '/quiz', '/skill-gap-analytics', '/recommendations', '/external-candidate-survey', '/resume-upload',]
routeMappingList[types.Admin] = ['/candidate-assessment-report', '/employee-survey-report', '/resume-uploaded-candidate-report', '/resume-upload', '/test-management', '/create-user', '/manage-skills', '/manage-roles', '/skill-gap-analytics']
routeMappingList[types.Client] = ['/available-resources',]


export const ProtectedRoutes = ({ children, path }) => {
    const isLogin = localStorage.getItem("isLogin")
    const userValues = localStorage.getItem("userValues") ? JSON.parse(localStorage.getItem("userValues")) : undefined;
    const jwt = localStorage.getItem("jwt")
    const browserConcurrentUser = localStorage.getItem("browerConcurrentLogin")

    if (isLogin && userValues && jwt && path === '/') {
        localStorage.setItem("browerConcurrentLogin", (parseInt(browserConcurrentUser) + 1))
        return <Navigate to='/dashboard' />
    }

    if ((!isLogin || !userValues || !jwt) && path === '/') {
        return children
    }

    if (path === '/dashboard') {
        return children
    }

    const typeList = routeMappingList[userValues.userType.trim()]

    if (!typeList || typeList.length === 0) {
        return <Navigate to='/' />
    }

    const isPathIncludes = typeList.includes(path.trim())
    if (isPathIncludes) {
        return children
    }

    if (isLogin) {
        return <Navigate to='/dashboard' />
    }

    return <Navigate to='/' />
}
