import React, { useState } from 'react'
import { useAppLayoutContext } from '../../contexts/LayoutContext';
import SubjectInput from '../../components/SubjectInput';
import Select from "react-select";
import { SKILL_LEVEL_OPTIONS } from "../../constants/skillLevelOptions";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import UploadFile from '../../components/FileUpload';
import { AxiosError } from 'axios';
import { showToast } from '../../util';
import DownloadFile from '../../components/FileDownload';
import { postMcqTypes } from '../../networking/apiFunctions/candidateApiFunctions';


const breadcrumbs = [
    {
        route: "/dashboard",
        title: "Home",
    },
    {
        route: "/test-management",
        title: "Create Assessment",
        active: true,
    },
];

const ManageTests = () => {
    const initialState = {
        testType: "",
        designationLevel: "1",
        time: 0,
        cutoffScore: 60,
        cutOffPercentage: 60.0,
        numberOfQuestions: 0, //added total no of ques 0
        numOfEasyQuestions: 0,
        numOfMediumQuestions: 0,
        numOfHardQuestions: 0,
        mcqTypesDetails: []
    }

    const [actionsModal, setActionsModal] = useState(false);
    const [selectedTopicData, setSelectedTopicData] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const { updateBreadcrumbData, showOverlay, hideOverlay } = useAppLayoutContext();
    const [test, setTest] = useState(initialState)
    const [totalQuestionsFromAllTopics, setTotalQuestionsFromAllTopics] = useState(0)
    const [enableFileOps, setEnableFileOps] = useState(true)

    const dataSource = test.mcqTypesDetails;

    updateBreadcrumbData(breadcrumbs);

    const openActionsModal = (data) => {
        console.log("🚀 ~ openActionsModal ~ data:", data)
        setSelectedTopicData(data);
        setActionsModal(true);
    };

    const columns = [
        { name: 'subject', header: 'Subject', minWidth: 50, defaultFlex: 1 },
        { name: 'numOfQuestions', header: 'Number of Questions', maxWidth: 1000, defaultFlex: 1 },

        {
            header: "Actions",
            defaultFlex: 1,
            render: ({ data }) => {
                return (
                    <div>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                                openActionsModal(data);
                            }}
                        >
                            Actions
                        </button>
                    </div>
                );
            },
        },
    ];

    const gridStyle = { minHeight: '20vh' };

    const updatePayload = async (payload) => {
        if (isLoading) { return }
        const json = JSON.stringify(payload);
        const blob = new Blob([json], {
            type: 'application/json'
        });

        const data = new FormData();
        enableFileOps && data.append("file", selectedFile);
        data.append("mcqTypesJsonContent", blob);
        try {
            showOverlay()
            setIsLoading(true)
            await postMcqTypes(data)
            showToast({ type: "success", message: `Test was created successfully` })
            setTest({ ...initialState });
            setSelectedFile(null);
        } catch (err) {
            if (err instanceof AxiosError || err instanceof Error) {
                console.log(err)
                showToast({ message: "Oops!! failed to create test", type: "error" });
            }
        } finally {
            setIsLoading(false)
            hideOverlay()
        }
    }

    return (
        <div className="container">
            <h4 className="items-center d-flex my-4 justify-content-center">
                Create Assessment
            </h4>


            {enableFileOps && (<div className="row">
                <div className="d-flex align-items-center justify-content-between py-2">
                    <UploadFile setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
                    <DownloadFile />
                </div>
            </div>)}

            <div>
                <div className='row col-xl-12 mt-4'>
                    <div className='col-xl-4'>
                        <div className='row col-md-12 mb-3'>
                            <label htmlFor="subName" className='col-md-6' style={{ marginTop: "5px" }}>Test Name</label>
                            <input
                                type="text"
                                className="form-control col me-2"
                                placeholder="Please add Assessment Name"
                                value={test.testType}
                                id="subName"
                                onChange={(e) => {
                                    setTest({
                                        ...test,
                                        testType: e.target.value,
                                    })
                                }}
                            />
                            {/* {formObject.userType?.validation === false && (
                                <p className="red">Please enter the Assessment Name</p>
                            )} */}

                        </div>

                        <div className='row col-md-12 mb-3'>
                            <label htmlFor="skillLevel" className='col-md-5' style={{ marginTop: "5px" }}>Level</label>
                            <Select
                                placeholder={`Select skill level`}
                                className='col ms-4 w-100'
                                options={SKILL_LEVEL_OPTIONS}
                                defaultValue={SKILL_LEVEL_OPTIONS[0]}
                                onChange={(opt) => {
                                    setTest({
                                        ...test,
                                        designationLevel: opt?.value
                                    })
                                }}
                            />
                        </div>

                        <div className='row col-md-12'>
                            <label htmlFor="noOfQuestions" className='col-md-6' style={{ marginTop: "5px" }}>Hard Question</label>
                            <input
                                // type="number"
                                className="form-control col me-2"
                                placeholder="Enter no of questions for this subject in this exam. Default is 30 *"
                                value={test.numOfHardQuestions}
                                id="noOfQuestions"
                                disabled={true}
                            // onChange={(e) => {
                            //     setTest({
                            //         ...test,
                            //         numOfHardQuestions: e.target.value ? parseInt(e.target.value) : 0,
                            //     })
                            // }}
                            />
                        </div>



                    </div>

                    <div className='col-xl-4'>
                        <div className='row col-md-12 mb-3 '>
                            <label htmlFor="noOfQuestions" className='col-md-7' style={{ marginTop: "5px" }}>Total No of Questions</label>
                            <input
                                type="number"
                                className="form-control col"
                                min={0}
                                placeholder="Enter no of questions in this exam. Default is 30 *"
                                value={test.numberOfQuestions}
                                id="totalnoOfQuestions"
                                onChange={(e) => {
                                    setTest({
                                        ...test,
                                        numberOfQuestions: e.target.value ? parseInt(e.target.value) : 0,
                                    })
                                }}
                            />
                        </div>
                        <br />
                        <br />

                        <div className='row col-md-12 mt-1'>
                            <label htmlFor="noOfQuestions" className='col-md-7' style={{ marginTop: "5px" }}>Easy Question</label>
                            <input
                                // type="number"
                                className="form-control col"
                                placeholder="Enter no of questions for this subject in this exam. Default is 30 *"
                                value={test.numOfEasyQuestions}
                                id="noOfQuestions"
                                disabled={true}
                            // onChange={(e) => {
                            //     setTest({
                            //         ...test,
                            //         numOfEasyQuestions: e.target.value ? parseInt(e.target.value) : 0,
                            //     })
                            // }}
                            />

                        </div>

                    </div>

                    <div className='col-xl-4'>

                        <div className='row col-md-12 mb-3'>
                            <label htmlFor="skillLevel" className='col-md-6' style={{ marginTop: "5px" }}>Duration</label>
                            <input
                                type="number"
                                className="form-control col"
                                placeholder="Enter no of questions in this exam. Default is 30 *"
                                min={0}
                                value={test.time}
                                id="totalnoOfQuestions"
                                onChange={(e) => {
                                    setTest({
                                        ...test,
                                        time: e.target.value ? parseInt(e.target.value) : 0,
                                    })
                                }}
                            />
                        </div>
                        <br />
                        <br />

                        <div className='row col-md-12 mt-1'>
                            <label htmlFor="noOfQuestions" className='col-md-6' style={{ marginTop: "5px" }}>Medium Question</label>
                            <input
                                // type="number"
                                className="form-control col"
                                placeholder="Enter no of questions for this subject in this exam. Default is 30 *"
                                value={test.numOfMediumQuestions}
                                id="noOfQuestions"
                                disabled={true}
                            // onChange={(e) => {
                            //     setTest({
                            //         ...test,
                            //         numOfMediumQuestions: e.target.value ? parseInt(e.target.value) : 0,
                            //     })
                            // }}
                            />

                        </div>


                    </div>
                </div>
            </div>

            {/* Create the input component */}
            <SubjectInput
                test={test}
                setTest={setTest}
                setTotalQuestionsFromAllTopics={setTotalQuestionsFromAllTopics}
                totalQuestionsFromAllTopics={totalQuestionsFromAllTopics} />

            <ReactDataGrid
                idProperty="id"
                columns={columns}
                dataSource={dataSource}
                style={gridStyle}
            />


            <div className='mt-4 items-end d-flex justify-content-end'>
                <button
                    disabled={test.name === '' || test.numberOfQuestions === 0 || test.time === 0 || test.numberOfQuestions !== totalQuestionsFromAllTopics}
                    onClick={() => {
                        updatePayload(test)
                    }} className="btn btn-primary">Create Assessment</button>

            </div>
        </div>
    )
}

export default ManageTests