import "./QuizPagination.css";
export default function PillsPagination({
  pagesData = [],
  onClick,
  currentQuestionIndex,
  selectedOption,
}) {
  return (
    <div className="card">
      <div className="card-body">
        <nav>
          <ul className="nav">
            {pagesData
              .map((item) => item.questionId)
              .map((item, index) => {
                return (
                  <li
                    role="button"
                    className={
                      "sm-pill-btn " +
                      (index === currentQuestionIndex ? "sm-active" : "") +
                      (item in selectedOption ? " sm-answered" : "")
                    }
                    key={index}
                    onClick={() => {
                      onClick(index);
                    }}
                  >
                    {index + 1}
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    </div>
  );
}
