import { useEffect, useState } from "react"
import { useAppLayoutContext } from "../../contexts/LayoutContext"
import { showToast } from "../../util"
import { AxiosError } from "axios"
import { getCandidateSkillGapAnalytics } from "../../networking/apiFunctions/adminApiFunctions"
import { useSearchParams } from "react-router-dom"


const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/skill-gap-analytics",
    title: "Skill Gap Analytics",
    active: true,
  },
]
export default function SkillGapAnalytics({ }) {
  const [analytics, setAnalytics] = useState(null)
  const { updateBreadcrumbData, showOverlay, hideOverlay } = useAppLayoutContext()
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("userValues")))
  let [searchParams, setSearchParams] = useSearchParams();
  let user_id = searchParams.get('user_id');
  if (user_id) {
    userDetails.userId = user_id;
  }

  useEffect(() => {
    if (user_id) {
      breadcrumbs[1] = {
        route: "/candidate-assessment-report",
        title: "Candidate Report",
      };
      breadcrumbs.push({
        route: "/skill-gap-analytics",
        title: "Skill Gap Analytics",
        active: true,
      },);
    }
    updateBreadcrumbData(breadcrumbs);
    fetchAssessmentReport();
  }, []);


  const fetchAssessmentReport = async () => {
    showOverlay()
    try {

      const data = await getCandidateSkillGapAnalytics(userDetails.userId)
      if (data && data.length > 0) {
        const scores = data[0]
        const tempcal = {
          OverAllPercentage:
            scores.subScore_Detail.reduce(
              (total, item) => total + item.subjectPercentage,
              0
            ) / scores.subScore_Detail.length,
          ScoresByTechnology: scores.subScore_Detail.map((item) => {
            return {
              technology: item.subjectName,
              percentage: item.subjectPercentage,
            }
          }),
        }
        if (userDetails.userType === 'Admin') {
          const getTopicDefuciltyInfo = data[0].subjectWiseDifficultyLevelScoreDetail.reduce((levelOb, item) => {
            if (item.subject) {
              if (!levelOb[item.subject]) {
                levelOb[item.subject] = [item]
              } else {
                levelOb[item.subject].push(item);
              }
            }
            return levelOb;
          }, {});
          tempcal.getTopicDefuciltyInfo = getTopicDefuciltyInfo;
        } else {
          tempcal.getTopicDefuciltyInfo = {};
        }
        setAnalytics(tempcal);
      }
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err)
        showToast({ message: "Oops!! Something went wrong.", type: "error" })
      }
    }
    finally {
      hideOverlay()
    }
  }

  return (
    <div className="container">
      <h4 className="my-4 text-center text-capitalize">Skill gap analytics</h4>
      <br />
      {analytics ? (
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="my-4 text-center text-capitalize">Overall Score</h4>
            <div className="row">
              <div className="col-md-12">
                <div className="col-12 responsive-changes pt-4">
                  Percentage after analytical analysis :{" "}
                  {analytics.OverAllPercentage}%
                </div>
              </div>
              <div className="col-md-12 pb-3 pt-4">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped"
                    role="progressbar"
                    style={{
                      width: analytics.OverAllPercentage + "%",
                      backgroundColor:
                        analytics.OverAllPercentage > 70
                          ? "green"
                          : analytics.OverAllPercentage > 55
                            ? "DarkKhaki"
                            : analytics.OverAllPercentage > 35
                              ? "orange"
                              : "red",
                    }}
                    aria-valuenow={analytics.OverAllPercentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {Math.floor(analytics.OverAllPercentage)}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h4 className="my-4 text-center text-capitalize">
              Descrete analysis
            </h4>

            <div className="row">
              {analytics.ScoresByTechnology.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="col-12">
                      {item.technology}
                    </div>
                    <div className="col-12 pb-3 pt-4">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: item.percentage + "%",
                            backgroundColor:
                              item.percentage > 70
                                ? "green"
                                : item.percentage > 55
                                  ? "DarkKhaki"
                                  : item.percentage > 35
                                    ? "orange"
                                    : "red",
                          }}
                          aria-valuenow={item.percentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {Math.floor(item.percentage)}%
                        </div>
                      </div>
                    </div>
                    {(analytics?.getTopicDefuciltyInfo[item?.technology] && analytics?.getTopicDefuciltyInfo[item?.technology].length > 0 && userDetails.userType === 'Admin') ?
                      (<div class="row mb-10">
                        {analytics?.getTopicDefuciltyInfo[item?.technology].map((el, i) => {
                          return <div class="col-4" key={i}>{el.difficultyLevel}</div>
                        })}
                        {analytics?.getTopicDefuciltyInfo[item?.technology].map((el, j) => {
                          return (
                            <div class="col-4 pb-3 pt-3">
                              <div class="progress">
                                <div class="progress-bar" role="progressbar" aria-valuenow={el.percentage} aria-valuemin="0"
                                  aria-valuemax="100" style={{
                                    width: el.percentage + "%", backgroundColor: el.difficultyLevel == 'Easy'
                                      ? "green"
                                      : el.difficultyLevel === 'Medium'
                                        ? "yellow"
                                        : el.difficultyLevel == 'Hard'
                                          ? "red"
                                          : "red",
                                  }}>{el.percentage}- {el.difficultyLevel}</div>
                              </div>
                            </div>)
                        })}
                      </div>) : (null)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <h4 className="text-center text-secondary">No data found</h4>
      )}
    </div>
  )
}
