const Helpers = {
    validateEmail: (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validatePassword:(password) => {
      var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      return re.test(password);
    }
  };
  export default Helpers;
  