
export const validateEmail = (email) => {
    const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
    if (emailRegex.test(email)) {
        return true
    }
    return false
}

const ResumeUploadValidation = {
    /**Function to validate file
     * @param {File} the file uploaded
     * @return {boolean}
     */
    validateUploadFile: (file) => {
        const fileNameSplitWithExtension = (file.name).split('.')
        const fileType = fileNameSplitWithExtension.length > 0 ? fileNameSplitWithExtension[fileNameSplitWithExtension.length - 1] : ''

        const allowedFileTypeList = ['docx', 'pdf', 'pptx', 'doc']
        return (allowedFileTypeList.includes(fileType.toLowerCase()))
    },

    validateUploadForm: (attachedFile, numberOfYearExperience, email,
        profileSummary, selectedLocationOption, selectedRoleOption, selectedPrimarySkillOption,
        selectedSecondarySkillOption) => {

        if (!attachedFile) return { status: false, message: 'Upload resume and try again !' }
        if (selectedLocationOption.length === 0) return { status: false, message: 'Select location from list' }
        if (selectedRoleOption.length === 0) return { status: false, message: 'Select Role from list' }
        if (selectedPrimarySkillOption.length === 0) return { status: false, message: 'Select primary skills from list' }
        if (selectedSecondarySkillOption.length === 0) return { status: false, message: 'Select secondary skills from list' }
        if (numberOfYearExperience === 0 || numberOfYearExperience < 1) return { status: false, message: 'Years of experience should be greater than zero' }
        if (!email || email.length === 0) return { status: false, message: 'Enter email address and try again !' }
        if (!validateEmail(email)) return { status: false, message: 'Enter valid email address' }
        if (!profileSummary || profileSummary.length === 0) return { status: false, message: 'Profile summary is important for us to know about you !' }


        return { status: true, message: 'validation success!' }

    }
}

export default ResumeUploadValidation