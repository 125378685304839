import { apiClient } from "../apiClient";

export const postEmployeeSatisfactionSurvey = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/survey/saveEmployeeSatisfactionSurvey`,
        data: payload
    })
    return response
}


