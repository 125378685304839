import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const UploadFile = (props) => {
    const { selectedFile, setSelectedFile } = props
    const [isFileExist, setIsFileExist] = useState(true)
    const ref = useRef()

    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0])
    }

    useEffect(() => {
        if (!selectedFile) {
            ref.current.value = "";
        }
    }, [selectedFile])

    return (
        <div className='row col-md-6 justify-content-center py-2 gx-2'>
            <h4 className='col-md-3'>Upload File</h4>
            <input type="file" ref={ref} accept=".xlsx, .xls" required onChange={handleFileUpload} className='col' />
        </div>
    )
}

export default UploadFile;