import { fieldsData } from "./fieldsData.js";

export const surveyJson = {
  title: "Candidate Information Form (CIF) - Internal Persistent Systems",
  // description:
  //   "Come Join Leader in Product Engineering Persistent Systems limited the fastest growing company globally with legacy of 30 years in the Industry. We have the best employee friendly initiatives with best Perks in the Industry and let's unleash your full potential. Be part of the growth journey with an Annualized Run Rate of Billion Dollar.",
  pages: [
    {
      name: "PersonalDetails",
      elements: [
        {
          type: "text",
          name: "name",
          title: "Enter your Name:",
          isRequired: fieldsData["name"],
        },
        {
          type: "text",
          name: "email",
          title: "Enter your Email:",
          inputMask: "email",
          isRequired: fieldsData["email"],
        },
        {
          type: "text",
          name: "contactNumber",
          title: "Enter your contact number:",
          isRequired: fieldsData["contactNumber"],
          maxLength: 10,
        },
        {
          type: "text",
          name: "location",
          title: "What is your current base location?",
          isRequired: fieldsData["location"],
        },
        {
          type: "radiogroup",
          name: "comfortable_Client_Location",
          title:
            "Are you willing to work from office from the below customer locations?",
          choices: [
            "Bengaluru - Bellandur",
            "Hyderabad - Madhapur Road",
            "Pune - Hinjewadi Phase 2",
            "Not possible",
          ],
          isRequired: fieldsData["comfortable_Client_Location"],
        },
        {
          type: "radiogroup",
          name: "long_leave_Plan",
          title: "Do you have any plans for a long leave in the next 1 year?",
          choices: ["Yes", "No"],
          isRequired: fieldsData["long_leave_Plan"],
        },
        {
          type: "radiogroup",
          name: "openShift",
          title: "Are you open to work in 7 AM - 4 PM shift?",
          choices: ["Yes", "No"],
          isRequired: fieldsData["openShift"],
        },
        {
          type: "radiogroup",
          name: "last_Role_Played",
          title: "Last Role Played",
          choices: [
              "Developer - Individual contributor",
              "Developer Team lead",
              "QA - Individual contributor",
              "QA Team lead",
              "Scrum master",
              "BA",
              "Technical Architect",
          ],
          hasOther: true,
          isRequired: fieldsData["last_Role_Played"]
      },      
        {
          type: "text",
          name: "months_on_Bench",
          title: "Since how many months are you on bench? (months)",
          isRequired: fieldsData["months_on_Bench"],
          inputType: "number",
        },
        {
          type: "text",
          name: "experience",
          title: "Total Experience (in YY.MM format)",
          isRequired: fieldsData["experience"],
        },
        {
          type: "radiogroup",
          name: "interview_Preferred_time_slot",
          title: "Preferred time slot to schedule the interview",
          choices: [
            "9AM - 1PM, Weekdays (Mon-Fri)",
            "2PM - 6PM, Weekdays(Mon-Fri)",
            "6PM - 10PM, Weekdays(Mon-Fri)",
            "Weekend (Sat, Sun)",
          ],
          isRequired: fieldsData["interview_Preferred_time_slot"],
        },
        {
          type: "radiogroup",
          name: "domain_Specific_Expertise",
          title: "Domain specific expertise",
          choices: [
            "Telecom domain 1-3 Years",
            "Telecom domain > 3 Years",
            "BFSI",
            "Healthcare",
            "Agritech",
            "E-commerce and M-commerce",
            "AI and Data Science",
            "FMCG/Retail/ Manufacturing domain",
            "Logistic & Transportation",
          ],
          isRequired: fieldsData["domain_Specific_Expertise"],
        },
        {
          type: "radiogroup",
          name: "technical_Profile_Competency",
          title: "How about technical profile competency?",
          choices: [
            "Front End Developer (Please select your frontend technologies from question#12)",
            "Back End Developer (Please select your backend technologies from question#11)",
            "Full Stack Developer (Please select your backend & frontend technologies from question#11 & 12)",
            "QA/Tester (Please select your backend technologies from question#15)",
            "Business Analyst",
            "No Mention In the List",
          ],
          isRequired: fieldsData["technical_Profile_Competency"],
        },
        {
          type: "checkbox",
          name: "frontend_Competence",
          title: "Hands on Frontend experience",
          choices: [
            "React",
            "React + Redux",
            "Angular",
            "Vue JS",
            "Jinja",
            "Jinja 2",
            "Mako",
            "String templates",
            "Backbone.js",
            "Ember.js",
            "Expert in HTML/CSS/JavaScript",
            "NA",
          ],
          isRequired: fieldsData["frontend_Competence"],
        },
        {
          type: "checkbox",
          name: "devops_Tool_Competence",
          title: "Hands on Devops tool competence - check all you have used.",
          choices: [
            "Bamboo",
            "Jenkins",
            "Ansible",
            "Terraform",
            "Gitlab",
            "Github",
            "AWS CI/CD",
            "Docker",
            "Kubernetes",
            "API gateway",
            "Kafka",
            "RabbitMQ",
            "AWS SES",
            "NA",
          ],
          isRequired: fieldsData["devops_Tool_Competence"],
        },
        {
          type: "checkbox",
          name: "backend_Competence",
          title: "Backend Competence",
          choices: [
            "Java",
            "Spring-boot",
            "Microservices",
            "REST APIs",
            "Junit",
            "NodeJS & ExpressJS",
            "Core Python",
            "Core Python + Django",
            "Core Python + Flask",
            "Core Python + Fast API",
            "Core Python + Bottle",
            "Core Python + Pyramid",
            ".Net and related technologies",
            "NA",
          ],
          isRequired: fieldsData["backend_Competence"],
        },
        {
          type: "checkbox",
          name: "quality_Engineering_Tool_Competence",
          title:
            "Quality engineering tool competence  - check all you have used.",
          choices: [
            "Selenium",
            "Appium",
            "JMeter",
            "Automation testing",
            "Junit",
            "Postman",
            "TestNG",
            "SoapUI",
            "UIPath",
            "RPA",
            "Cucumber",
            "Unittests",
            "PyTest",
            "NA",
            "Python Selenium",
          ],
          isRequired: fieldsData["quality_Engineering_Tool_Competence"],
        },
        {
          type: "checkbox",
          name: "cloud_Server_Exposure",
          title: "Hands on Cloud server exposure - Check whichever applies.",
          choices: [
            "AWS",
            "GCP",
            "MS Azure",
            "Digital Ocean",
            "Private cloud/Datacenters",
            "PCF",
            "NA",
          ],
          isRequired: fieldsData["cloud_Server_Exposure"],
        },
        {
          type: "checkbox",
          name: "database_Exposure",
          title: "Hands on Database exposure",
          choices: [
            "MySQL",
            "MS SQL",
            "Posgres",
            "AWS RDS",
            "Graph DB",
            "Graph QL",
            "Cassandra",
            "Hbase",
            "MongoDB or any other document DB",
            "DynamoDB",
            "NA",
          ],
          isRequired: fieldsData["database_Exposure"],
        },
        {
          type: "checkbox",
          name: "process_Knowledge",
          title: "Process knowledge",
          choices: ["Scrum", "Kanban", "XP", "Waterfall", "NA"],
          isRequired: fieldsData["process_Knowledge"],
        },
        {
          type: "comment",
          name: "certification",
          title: "Any other additional capabilities or certifications you own",
        },
        {
          type: "radiogroup",
          name: "project_assignment_availability",
          title: "When are you available for Project assignment?",
          choices: ["Immediate", "15 days to 1 month", ">1 month"],
        },
        {
          type: "radiogroup",
          name: "attended_Internal_Client_Interview",
          title: "Have you attended any client interviews in last one week?",
          choices: ["Yes", "No"],
        },
        {
          type: "comment",
          name: "tool_stack_worked",
          title:
            "Any other tool/stack you worked on, which is not specified above. [Please enter comma separated values]",
        },
        {
          type: "radiogroup",
          name: "frontend_rating",
          title: "Front End - rate yourself (React/Angular)",
          choices: ["Expert", "Average", "Novice"],
        },
        {
          type: "radiogroup",
          name: "backend_rating",
          title: "Back end - Java/SpringBoot/Micro Services",
          choices: ["Expert", "Average", "Novice"],
        },
        {
          type: "radiogroup",
          name: "html_Css_Java_Script_rating",
          title: "HTML/CSS/JavaScript",
          choices: ["Expert", "Average", "Novice"],
        },
        {
          type: "radiogroup",
          name: "aws_rating",
          title: "AWS",
          choices: ["Expert", "Average", "No Experience"],
        },
        {
          type: "radiogroup",
          name: "devOps_rating",
          title: "DevOps",
          choices: ["Expert", "Average", "No Experience"],
        },
        {
          type: "comment",
          name: "summary",
          title:
            "Please describe your overall experience Summary in three to five points. Please note it would be used to generate your resume",
          isRequired: fieldsData["summary"],
        },
        {
          type: "comment",
          name: "project1",
          title:
            "Please describe your project 1 detail. Please note it would be used to generate your resume",
          isRequired: fieldsData["project1"],
          maxLength: 150,
        },
        {
          type: "comment",
          name: "project2",
          title:
            "Please describe your project 2 detail. Please note it would be used to generate your resume",
          isRequired: fieldsData["project2"],
          maxLength: 150,
        },
        {
          type: "comment",
          name: "project3",
          title:
            "Please describe your project 3 detail. Please note it would be used to generate your resume",
          isRequired: fieldsData["project3"],
          maxLength: 150,
        },
        {
          type: "comment",
          name: "certification_detail",
          title:
            "Please describe your certification detail . Please note it would be used to generate your resume",
          isRequired: fieldsData["certification_detail"],
        },
      ],
    },
  ],
  completeText: "Submit",
  completedHtml: "Thank you for your feedback!",
};
