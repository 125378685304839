import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import LoginValidator from "./components/auth/LoginValidator";
import DashBoard from "./components/Dashboard";
import Header from "./components/auth/Header";
import SkillGapAnalytics from "./components/skillGapAnalytics/SkillGapAnalytics";
import Recommendations from "./components/recommendations/Recommendations";
import CandidateSurvey from "./components/candidateSurvey/CandidateSurvey";
import ExternalCandidateSurvey from "./components/externalCandidateSurvey/ExternalCandidateSurvey";
import RGF from "./components/ResumeGenarationForm/Rgf";
import QuizHome from "./pages/QuizHome.js";
import Quize from "./pages/Quiz.js";
import Footer from "./components/layout/Footer";
import EmployeeSurveyReport from "./pages/EmployeeSurveyReport.js";
import CandidateAssessmentReport from "./pages/CandidateAssessmentReport";
import WildcardCandidateAssessmentReport from "./pages/WildcardCandidateAssessmentReport";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutProvider } from "./contexts/LayoutContext";
import AppOverlay from "./components/AppOverlay";
import AvailableResources from "./pages/AvailableResources";
import ManageTests from "./pages/ManageTests/index.js";
import { ResumeUpload } from "./components/resumeUpload/ResumeUpload";

import Registration from "./components/auth/Registration.js";
import ManageSkills from "./pages/ManageSkills";
import RoleSkills from "./pages/RoleSkills";
import { ProtectedRoutes } from "./protectedRoutes/ProtectedRoutes.js";
import { logoutAllTabs } from "./utils/logoutAllTabs.js";
function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isVisibilityChanged, setIsVisibilityChanged] = useState(false);


  useEffect(() => {
    logoutAllTabs()
    if (localStorage.getItem("isLogin")) {
      setIsUserLoggedIn(true);
    }

  }, []);

  return (
    <LayoutProvider>
      <div>
        <Helmet>
          <meta
            httpEquiv="Content-Security-Policy"
            content={`
            default-src * 'unsafe-inline' 'unsafe-eval'; script-src * 'unsafe-inline' 'unsafe-eval'; connect-src * 'unsafe-inline'; img-src * data: blob: 'unsafe-inline'; frame-src *; style-src * 'unsafe-inline';
                `}
          ></meta>
        </Helmet>
      </div>
      <div className="App h-100">
        <BrowserRouter>
          <div className="d-flex flex-column h-100">
            <div className="flex-fill">
              <>
                {isUserLoggedIn
                  ? (
                    <Header setIsUserLoggedIn={setIsUserLoggedIn} />
                  )
                  : null}

              </>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <ProtectedRoutes path='/'>
                      <LoginValidator setIsUserLoggedIn={setIsUserLoggedIn} />
                    </ProtectedRoutes>}
                />

                <Route path="/dashboard" element={
                  <ProtectedRoutes path='/dashboard'>
                    <DashBoard />
                  </ProtectedRoutes>} />

                {/* candidate routes */}
                <Route path="/quiz-home" element={
                  <ProtectedRoutes path='/quiz-home'>
                    <QuizHome />
                  </ProtectedRoutes>} />

                <Route path="/quiz" element={
                  <ProtectedRoutes path='/quiz'>
                    <Quize />
                  </ProtectedRoutes>} />

                <Route path="/skill-gap-analytics" element={
                  <ProtectedRoutes path='/skill-gap-analytics'>
                    <SkillGapAnalytics />
                  </ProtectedRoutes>} />

                <Route path="/recommendations" element={
                  <ProtectedRoutes path='/recommendations'>
                    <Recommendations />
                  </ProtectedRoutes>} />

                <Route path="/resume-upload" element={
                  <ProtectedRoutes path='/resume-upload'>
                    <ResumeUpload />
                  </ProtectedRoutes>} />

                {/* admin routes*/}
                <Route
                  path="/candidate-assessment-report"
                  element={
                    <ProtectedRoutes path='/candidate-assessment-report'>
                      <CandidateAssessmentReport />
                    </ProtectedRoutes>} />

                <Route
                  path="/employee-survey-report"
                  element={
                    <ProtectedRoutes path='/employee-survey-report'>
                      <EmployeeSurveyReport />
                    </ProtectedRoutes>} />

                <Route
                  path="/resume-uploaded-candidate-report"
                  element={
                    <ProtectedRoutes path='/resume-uploaded-candidate-report'>
                      <WildcardCandidateAssessmentReport />
                    </ProtectedRoutes>} />

                <Route
                  path="/test-management"
                  element={
                    <ProtectedRoutes path='/test-management'>
                      <ManageTests />
                    </ProtectedRoutes>} />

                <Route
                  path="/create-user"
                  element={
                    <ProtectedRoutes path='/create-user'>
                      <Registration />
                    </ProtectedRoutes>} />

                <Route path="/manage-skills" element={
                  <ProtectedRoutes path='/manage-skills'>
                    <ManageSkills />
                  </ProtectedRoutes>} />

                <Route path="/manage-roles" element={
                  <ProtectedRoutes path='/manage-roles'>
                    <RoleSkills />
                  </ProtectedRoutes>} />

                {/* Client routes*/}
                <Route
                  path="/available-resources"
                  element={
                    <ProtectedRoutes path='/available-resources'>
                      <AvailableResources />
                    </ProtectedRoutes>} />

                {/* Routes which can access with no login required*/}
                <Route path="/resume-generation-form" element={<RGF />} />
                <Route path="/candidate-survey" element={<CandidateSurvey />} />
                <Route path="/external-candidate-survey" element={<ExternalCandidateSurvey />} />




                {/* <Route path="/candidateSurvey" element={<CandidateSurvey />} />
                <Route path="/employee-profile" element={<MyProfile />} /> */}


              </Routes>
            </div>
            <>{isUserLoggedIn && <Footer />}</>
          </div>
        </BrowserRouter>
        <ToastContainer />
        <AppOverlay />
      </div>
    </LayoutProvider>
  );
}

export default App;
