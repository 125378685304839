import { BroadcastChannel } from "broadcast-channel"

const logoutChannel = new BroadcastChannel('logout')

export const logout = () => {
    logoutChannel.postMessage('logout')
    localStorage.clear()
    window.location.href = window.location.origin + '/'
}

export const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
        logout()
        logoutChannel.close()
    }
}

