import axios from 'axios'
import { API_HOST_URL } from "../constants";
import { v4 as uuidv4 } from 'uuid'

export const apiClient = axios.create({
  baseURL: API_HOST_URL,
  validateStatus: function (status) {
    return status >= 200 && status < 400
  }
})

function authRequestInterceptor(config) {
  if (config === undefined || config === null) {
    throw new Error('config is undefined')
  }

  if (config.headers === undefined || config.headers === null) {
    throw new Error('config.headers is undefined')
  }

  let headerAccept = 'application/json'
  if (config.url?.includes('candidate/resumeSave') || (config.url?.includes('candidate/mcqtypes') && config.method === 'post') || config.url?.includes('candidate/uploadFileVideo')) {
    headerAccept = 'multipart/form-data'
  }

  let isAuthorizationTokenRequired = true
  if (config.url?.includes('/login') || config.url?.includes('/cognito/signIn') || config.url?.includes('/signup')) {
    isAuthorizationTokenRequired = false
    config.headers.SESSION_ID = uuidv4()
  }


  const token = localStorage.getItem("jwt")
  config.headers.Accept = headerAccept
  if (isAuthorizationTokenRequired) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

function responseFormatterInterceptor(response) {
  return response.data
}

function errorHandlerInterceptor(error) {
  console.log(error)
  const message = error.response?.data || error.message
  throw new Error(message)
}

apiClient.interceptors.request.use(authRequestInterceptor)
apiClient.interceptors.response.use(responseFormatterInterceptor, errorHandlerInterceptor)


