import { fieldsData } from "./fieldsData.js";

export const surveyJson = {
  title: "Candidate Information Form (CIF)",
  description:
    "Opportunity with Persistent - 268% growth since 2020, Start your journey with us by responding to this form. This should only take your 8-10 mins.23000 employees+, 21 Global locations",
  pages: [
    {
      name: "PersonalDetails",
      elements: [
        {
          type: "text",
          name: "name",
          title: "Name",
          isRequired: fieldsData["name"],
        },
        {
          type: "text",
          name: "email",
          title: "Email ID",
          // inputMask: "email",
          isRequired: fieldsData["email"],
        },
        {
          type: "text",
          name: "contactNumber",
          title: "Contact Number",
          isRequired: fieldsData["contactNumber"],
          maxLength: 10,
        },
        {
          type: "text",
          name: "experience",
          title: "Total Experience (YY.MM format)",
          isRequired: fieldsData["experiance"],
        },
        {
          type: "text",
          inputType: "date",
          name: "dob",
          title: "Date of Birth",
          isRequired: fieldsData["dob"],
        },
        {
          type: "text",
          name: "payroll",
          title: "Current Organization (Payroll company)",
          isRequired: fieldsData["payroll"],
        },
        {
          type: "text",
          inputType: "date",
          name: "joiningdate",
          title: "By when can you join Persistent",
          isRequired: fieldsData["joiningdate"],
        },
        {
          type: "radiogroup",
          name: "comfortable_Client_Location",
          title:
            "Are you willing to work from office from the below customer locations?",
          choices: [
            "Bengaluru - Bellandur",
            "Hyderabad - Madhapur Road",
            "Pune - Hinjewadi Phase 2",
            "Not possible",
          ],
          isRequired: fieldsData["comfortable_Client_Location"],
        },
        {
          type: "radiogroup",
          name: "long_leave_Plan",
          title: "Do you have any plans for a long leave in the next 1 year?",
          choices: ["Yes", "No"],
          isRequired: fieldsData["long_leave_Plan"],
        },
        {
          type: "radiogroup",
          name: "openShift",
          title: "Are you open to work in 7 AM - 4 PM shift?",
          choices: ["Yes", "No"],
          isRequired: fieldsData["openShift"],
        },
        {
          type: "text",
          name: "qualification",
          title: "What is your highest educational qualification",
          isRequired: fieldsData["qualification"],
        },
        {
          type: "text",
          name: "passingYear",
          title: "Year of passout",
          isRequired: fieldsData["passingYear"],
        },
        {
          type: "radiogroup",
          name: "last_Role_Played",
          title: "Last Role Played",
          choices: [
            "Backend Developer - Individual contributor",
            "Developer Team lead",
            "QA - Individual contributor",
            "QA Team lead",
            "Scrum master",
            "Business Analyst",
            "Technical Architect",
            "Full Stack Developer",
            "Frontend Developer - Individual contributor",
          ],
          hasOther: true,
          isRequired: fieldsData["last_Role_Played"],
        },
        {
          type: "radiogroup",
          name: "domain_Specific_Expertise",
          title: "Domain specific expertise",
          choices: [
            "Telecom domain 1-3 Years",
            "Telecom domain > 3 Years",
            "BFSI",
            "Healthcare",
            "Agritech",
            "E-commerce and M-commerce",
            "AI and Data Science",
            "FMCG/Retail/ Manufacturing domain",
            "Logistic & Transportation",
          ],
          hasOther: true,
          isRequired: fieldsData["domain_Specific_Expertise"],
        },
        {
          type: "radiogroup",
          name: "technical_Profile_Competency",
          title: "How about technical profile competency?",
          choices: [
            "Front End Developer (Select technologies in Q16)",
            "Back End Developer (Select technologies in Q18)",
            "Full Stack Developer (Select technologies in Q16 & Q18)",
            "QA/Tester (Select technologies in Q19)",
            "Business Analyst",
          ],
          isRequired: fieldsData["technical_Profile_Competency"],
        },
        {
          type: "checkbox",
          name: "frontend_Competence",
          title: "Hands on Frontend experience (please check as applicable)",
          choices: [
            "Not Applicable",
            "React",
            "React + Redux",
            "Angular",
            "Vue JS",
            "Jinja",
            "Jinja 2",
            "Mako",
            "String templates",
            "Backbone.js",
            "Ember.js",
            "Expert in HTML/CSS/JavaScript",
            "TypeScript",
            "Bootstrap",
          ],
          hasOther: true,
          isRequired: fieldsData["frontend_Competence"],
        },
        {
          type: "checkbox",
          name: "devops_Tool_Competence",
          title: "Hands on Devops tool competence (please check as applicable)",
          choices: [
            "Not Applicable",
            "Bamboo",
            "Jenkins",
            "Ansible",
            "Terraform",
            "Git",
            "AWS CI/CD",
            "Azure DevOps",
            "Docker",
            "Kubernetes",
            "API gateway",
            "Kafka",
            "RabbitMQ",
            "AWS SES",
          ],
          hasOther: true,
          isRequired: fieldsData["devops_Tool_Competence"],
        },
        {
          type: "checkbox",
          name: "backend_Competence",
          title: "Backend competence (please check as applicable)",
          choices: [
            "Not Applicable",
            "Java",
            "Spring-boot",
            "Microservices",
            "REST APIs",
            "Junit",
            "NodeJS & ExpressJS",
            "Core Python",
            "Core Python + Django",
            "Core Python + Flask",
            "Core Python + Fast API",
            "Core Python + Bottle",
            "Core Python + Pyramid",
            ".Net and related technologies",
          ],
          isRequired: fieldsData["backend_Competence"],
        },
        {
          type: "checkbox",
          name: "quality_Engineering_Tool_Competence",
          title:
            "Quality engineering tool competence (please check as applicable)",
          choices: [
            "No Experience",
            "Java Selenium",
            "Appium",
            "JMeter",
            "Automation testing",
            "Junit",
            "Postman",
            "TestNG",
            "SoapUI",
            "UIPath",
            "RPA",
            "Cucumber",
            "Unittests",
            "PyTest",
            "Python Selenium",
          ],
          isRequired: fieldsData["quality_Engineering_Tool_Competence"],
        },
        {
          type: "checkbox",
          name: "cloud_Server_Exposure",
          title: "Hands on Cloud server exposure (please check as applicable)",
          choices: [
            "No Experience",
            "AWS",
            "GCP",
            "MS Azure",
            "Digital Ocean",
            "Private cloud/Datacenters",
            "PCF",
          ],
          isRequired: fieldsData["cloud_Server_Exposure"],
        },
        {
          type: "checkbox",
          name: "database_Exposure",
          title: "Hands on Database exposure (please check as applicable)",
          choices: [
            "No Experience",
            "MySQL",
            "MS SQL",
            "Posgres",
            "AWS RDS",
            "Graph DB",
            "Graph QL",
            "Cassandra",
            "Hbase",
            "MongoDB or any other document DB",
            "DynamoDB",
          ],
          isRequired: fieldsData["database_Exposure"],
        },
        {
          type: "checkbox",
          name: "process_Knowledge",
          title:
            "Software development methodologies & framework (please check as applicable)",
          choices: [
            "No Experience",
            "Scrum",
            "Kanban",
            "XP",
            "Waterfall",
          ],
          hasOther: true,
          isRequired: fieldsData["process_Knowledge"],
        },
        {
          type: "comment",
          name: "certification",
          title: "Any other additional capabilities or certification you own",
        },
        {
          type: "comment",
          name: "additionalStack",
          title:
            "Any other tool/stack you worked on, which is not specified above. [Please enter comma separated values]",
        },
        {
          type: "comment",
          name: "tool_stack_worked",
          title:
            "Any other tool/stack you worked on, which is not specified above. [Please enter comma separated values]",
        },
        {
          type: "radiogroup",
          name: "frontend_rating",
          title: "Front End - rate yourself (React/Angular)",
          choices: ["Expert", "Average", "Novice"],
        },
        {
          type: "radiogroup",
          name: "backend_rating",
          title: "Back end - Java/SpringBoot/Micro Services",
          choices: ["Expert", "Average", "Novice"],
        },
        {
          type: "radiogroup",
          name: "html_Css_Java_Script_rating",
          title: "HTML/CSS/JavaScript",
          choices: ["Expert", "Average", "Novice"],
        },
        {
          type: "radiogroup",
          name: "aws_rating",
          title: "AWS",
          choices: ["Expert", "Average", "No Experience"],
        },
        {
          type: "radiogroup",
          name: "devOps_rating",
          title: "DevOps",
          choices: ["Expert", "Average", "No Experience"],
        },
        {
          type: "radiogroup",
          name: "interview_Preferred_time_slot",
          title: "Preferred time slot to schedule the interview",
          choices: [
            "9AM - 1PM, Weekdays (Mon-Fri)",
            "2PM - 6PM, Weekdays(Mon-Fri)",
            "6PM - 10PM, Weekdays(Mon-Fri)",
            "Weekend (Sat, Sun)",
          ],
          isRequired: fieldsData["interview_Preferred_time_slot"],
        },
      ],
    },
  ],
  completeText: "Submit",
  completedHtml: "Thank you for your feedback!",
};
