export const SKILL_LEVEL_OPTIONS = [
    {
        id: 1,
        name: "beginner",
        label: "beginner",
        value: "1",
    },
    {
        id: 2,
        name: "intermediate",
        label: "intermediate",
        value: "2",
    }, {
        id: 3,
        name: "expert",
        label: "expert",
        value: "3",
    },
];