import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import AdminDashboard from "./Dashboards/AdminDashboard";
import CandidateDashboard from "./Dashboards/CandidateDashboard";
import ClientDashboard from "./Dashboards/ClientDashboard";
import EmployeeDashboard from "./Dashboards/EmployeeDashboard";
import { useAppLayoutContext } from "../contexts/LayoutContext";

const DashBoard = () => {
  const { updateBreadcrumbData } = useAppLayoutContext();
  let userData = localStorage.getItem("userValues") ? JSON.parse(localStorage.getItem("userValues")) : {};
  const [userDetails] = useState(
    userData
  );
  useEffect(() => {
    updateBreadcrumbData([]);
  }, []);

  return (
    <>
      <br />
      {userDetails.userType === "Employee" ? (
        <EmployeeDashboard userDetails={userDetails} />
      ) : null}
      {userDetails.userType === "Candidate" ? (
        <CandidateDashboard userDetails={userDetails} />
      ) : null}
      {userDetails.userType === "Admin" ? (
        <AdminDashboard userDetails={userDetails} />
      ) : null}
      {userDetails.userType === "Client" ? (
        <ClientDashboard userDetails={userDetails} />
      ) : null}
    </>
  );
};
export default DashBoard;
