import { useAppLayoutContext } from "../../contexts/LayoutContext";

export default function AppOverlay() {
  const { overlay } = useAppLayoutContext();
  return (
    <div
      className="app-overlay"
      style={{ display: overlay > 0 ? "block" : "none" }}
    >
      <div className="position-absolute top-50 start-50">
        {/* <h4 className="fs-4">Loading...</h4> */}
        <h4 className="fs-4">
          <i
            className="fa fa-spinner fa-pulse"
            aria-hidden="true"
            style={{ color: "white", fontSize: "2.9rem" }}
          ></i>
        </h4>
      </div>
    </div>
  );
}
