import { useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { AxiosError } from "axios";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import ModalComponent from "../../components/ModalComponent";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import ResourceSearchComponent from "../../components/ResourceSearchComponent";
import { useSearchParams } from "react-router-dom";
import { postBookMarkCandidate, postCandidateSearch } from "../../networking/apiFunctions/clientApiFunctions";
import { getSoftSkillsList } from "../../networking/apiFunctions/serviceApiFunctions";
const gridStyle = { minHeight: 550 };

const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/available-resources",
    title: "Available Resources",
    active: true,
  },
];

const initialState = {
  interviewDate: "",
  projectStartDate: "",
  projectDuration: "",
};
export default function AvailableResources() {
  const [userDetails, setUserID] = useState(
    JSON.parse(localStorage.getItem("userValues"))
  );
  const [softSkillList, setSoftSkillList] = useState([])

  const { updateBreadcrumbData, showOverlay, hideOverlay } =
    useAppLayoutContext();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [selectedCandidateData, setSelectedCandidateData] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const onActionsModalClose = () => {
    setActionsModal(false);
    setSelectedCandidateData(null);
  };
  const openActionsModal = (data) => {
    setSelectedCandidateData(data);
    setActionsModal(true);
  };
  updateBreadcrumbData(breadcrumbs);

  const ActionsForm = (data) => {
    const [selectedValues, setSelectedValues] = useState(initialState);

    const inputChangeHandler = (e) => {
      setSelectedValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
    };
    const onSubmitBookmark = async (e) => {
      if (loading) { return }
      e.preventDefault();
      const bookmarkdata = {
        ...selectedValues,
        candidateId: selectedCandidateData.candidateId,
        userId: userDetails.userId
      };
      try {
        setLoading(true)
        showOverlay()
        await postBookMarkCandidate(bookmarkdata)
        showToast({ type: "success", message: "Candidate Bookmarked.", })
        fetchAvailableResources()
        onActionsModalClose()
      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          console.log(err);
          showToast({ type: "error", message: "Failed to Bookmark Candidate. Please try again." })
        }
      } finally {
        setLoading(false)
        hideOverlay()
      }
    }

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form onSubmit={onSubmitBookmark}>
                <div className="row gy-2">
                  <div className="col-12">
                    <label htmlFor="interviewDate" className="form-label">
                      Interview Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="interviewDate"
                      required
                      value={selectedValues.interviewDate}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="projectStartDate" className="form-label">
                      Project Start Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="projectStartDate"
                      required
                      value={selectedValues.projectStartDate}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="projectDuration" className="form-label">
                      Project Duration
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="projectDuration"
                      // placeholder="Interview Date"
                      required
                      value={selectedValues.projectDuration}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-12 text-end">
                    <button type="submit" className="btn btn-primary">
                      Bookmark
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };
  const fetchAvailableResources = async () => {
    if (loading) { return }
    try {
      setLoading(true)
      showOverlay()
      const searchparam = searchParams.get("search")
        ? JSON.parse(atob(searchParams.get("search")))
        : [];

      const payload = searchparam.map((e) => e.label)

      const result = await postCandidateSearch(payload)
      setDataSource(result)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err);
        showToast({ type: "error", message: "Failed to fetch Available Candidate Profiles." })
      }
    } finally {
      setLoading(false)
      hideOverlay()
    }
  };

  useEffect(() => {
    fetchAvailableResources();
  }, [searchParams.get("search")]);
  const columns = [
    {
      name: "candidateId",
      header: "Candidate Id",
      defaultWidth: 140,
    },
    {
      name: "location",
      header: "Location",
      defaultWidth: 140,
      render: ({ value }) => {
        if (value) {
          return <p className="text-wrap">{value}</p>;
        }
      },
    },
    {
      name: "skill",
      header: "Skills",
      defaultWidth: 180,
      render: ({ value }) => {
        if (value) {
          return <p className="text-wrap">{value}</p>;
        }
      },
    },
    {
      name: "summary",
      header: "Summary",
      type: "string",
      defaultWidth: 280,
      render: ({ value }) => {
        if (value) {
          return <p className="text-wrap">{value}</p>;
        }
      },
    },
    {
      name: "experience",
      header: "Experience",
      type: "number",
      defaultWidth: 120,
      filterEditor: NumberFilter,
      render: ({ value }) => {
        return <div>{value} Years</div>;
      },
    },
    {
      name: "expiryDate",
      header: "Profile Expiry Date",
      type: "date",
      filterEditor: DateFilter,
    },
    {
      name: "profileUrl",
      header: "Profile",
      type: "string",
      defaultWidth: 140,
      render: ({ value }) => {
        if (value) {
          return (
            <a
              className="btn btn-primary btn-sm"
              href={value}
              target="_blank"
              title="Download Profile"
            >
              {" Download Profile"}
            </a>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      header: "Bookmark",
      minWidth: 170,
      defaultFlex: 1,
      render: ({ data }) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                openActionsModal(data);
              }}
            >
              {data.bookmarked ? 'Bookmarked' : 'Bookmark Candidate'}
            </button>
          </div>
        );
      },
    },
  ];
  const filterValue = [
    { name: "candidateId", operator: "contains", type: "string", value: "" },
    { name: "location", operator: "contains", type: "string", value: "" },
    { name: "skill", operator: "contains", type: "string", value: "" },
    { name: "summary", operator: "contains", type: "string", value: "" },
    { name: "experience", operator: "eq", type: "number", value: null },
    { name: "expiryDate", operator: "before", type: "date", value: "" },
  ];

  useEffect(() => {
    handleFetchSoftSkills()
  }, [])


  const handleFetchSoftSkills = async () => {
    if (loading) { return }
    try {
      setLoading(true)
      showOverlay()
      const result = await getSoftSkillsList()
      setSoftSkillList(result)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err);
        showToast({ type: "error", message: "Failed to fetch softSkill list." })
      }
    } finally {
      setLoading(false)
      hideOverlay()
    }
  }

  /**
   * Function responsible to format softSkills List as required by dropDown list
   * @param {List} softSkillListFromApi 
   * @returns 
   */
  const formatSkillsList = (softSkillListFromApi) => {
    return (
      softSkillListFromApi.filter((skillFilter) => {
        return skillFilter.status ? skillFilter : ''
      }).map((skillObj) => {
        const { softSkillId, softSkillDescription, status } = skillObj
        if (status) {
          return { value: softSkillId, label: softSkillDescription }
        }
      })
    )
  }


  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mx-auto py-4 mt-4">
              <ResourceSearchComponent softSkillList={softSkillList} />
            </div>
          </div>
        </div>
      </div>
      <h4 className="items-center d-flex mb-4 mt-2 justify-content-center">
        Available Resources
      </h4>
      <div className="container">
        <div className="row">
          <ReactDataGrid
            columns={columns}
            dataSource={dataSource}
            idProperty="id"
            style={gridStyle}
            pagination
            defaultLimit={100}
            loading={loading}
            defaultFilterValue={filterValue}
            enableSelection={false}
            rowHeight={null}
            columnContextMenuConstrainTo={false}
          />
        </div>
      </div>
      {actionsModal ? (
        <ModalComponent
          modalId={"bookmark-actionmodal"}
          title={"Bookmark Candidate"}
          showFooter={false}
          onCloseClick={onActionsModalClose}
          footer={
            <>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onActionsModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
              // onClick={updateCandidateStatus}
              >
                Save
              </button>
            </>
          }
          modalBody={<ActionsForm />}
        />
      ) : null}
    </>
  );
}
