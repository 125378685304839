import { useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import ModalComponent from "../../components/ModalComponent";
import { directlyUploadedCandidates, putCandidateAssessmentPerformanceStatus } from "../../networking/apiFunctions/adminApiFunctions";

const gridStyle = { minHeight: 550 };
const breadcrumbs = [
    {
        route: "/dashboard",
        title: "Home",
    },
    {
        route: "/resume-uploaded-candidate-report",
        title: "Resume Uploaded Candidate Report",
        active: true,
    },
];

const initialState = { selectedAction: "" };

export default function WildcardCandidateAssessmentReport() {
    const { updateBreadcrumbData, showOverlay, hideOverlay } =
        useAppLayoutContext();
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionsModal, setActionsModal] = useState(false);
    const [selectedCandidateData, setSelectedCandidateData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(initialState);

    const onActionsModalClose = () => {
        setActionsModal(false);
        setSelectedCandidateData(null);
        setSelectedOption(initialState);
    };
    const openActionsModal = (data) => {
        setSelectedCandidateData(data);
        setActionsModal(true);
    };
    updateBreadcrumbData(breadcrumbs);

    const [userDetails] = useState(
        JSON.parse(localStorage.getItem("userValues"))
    );

    const fetchAssessmentReport = async () => {
        setLoading(true);
        try {
            //added by anshu Kumar
            const data = await directlyUploadedCandidates();
            setDataSource(data || []);
        } catch (error) {
            console.log(error);
            showToast({ message: "Oops!! Something went wrong.", type: "error" });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        // call api and update the datasource
        fetchAssessmentReport();
    }, []);

    const updateCandidateStatus = async () => {
        if (loading) { return }

        if (selectedOption.selectedAction !== "") {
            showOverlay(true);
            try {
                let data = {
                    userId: userDetails.userId, //adminId
                    candidateUserId: selectedCandidateData.userId,
                    candidateEmail: selectedCandidateData.email,
                    clientSelectedRejected: "", // Selected/Rejected
                    promotionStatus: "",
                    bookmarked: false,
                };
                if (selectedOption.selectedAction === "Promoted") {
                    data["promotionStatus"] = "Promoted";
                }
                if (selectedOption.selectedAction === "Declined") {
                    data["promotionStatus"] = "Declined";
                }
                setLoading(true)

                const result = await putCandidateAssessmentPerformanceStatus(data)
                showToast({ message: result, type: "success" });
                onActionsModalClose();
                fetchAssessmentReport();

            } catch (error) {
                showToast({ message: "Oops!! Something went wrong.", type: "error" });
            } finally {
                setLoading(false)
                hideOverlay(false);
            }
        } else {
            showToast({ type: "error", message: "Please select an action" });
        }
    };
    const handleOptionChange = (e) => {
        setSelectedOption((pre) => ({ ...pre, [e.target.name]: e.target.value }));
    };

    const ActionsForm = () => {
        return (
            <>
                <div className="my-4">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <select
                            className="form-select form-select-lg"
                            name="selectedAction"
                            onChange={handleOptionChange}
                            value={selectedOption.selectedAction}
                        >
                            <option value="">Select Action</option>
                            <option value="Promoted">Promoted to Client</option>
                            <option value="Declined">Declined to Client</option>
                            {/* <option value="Download Resume (with mask)">
                                Download Resume
                            </option> */}
                        </select>
                    </form>
                </div>
            </>
        );
    };
    const columns = [
        {
            name: "id",
            header: "ID",
            // defaultWidth: 200,
            flex: 1
        },
        {
            name: "email",
            header: "Email",
            // defaultWidth: 200,
            flex: 2
        },
        {
            name: "skill",
            header: "Skill",
            // defaultWidth: 100,
            flex: 1
        },
        {
            name: "secondarySkill",
            header: "Secondary Skill",
            // defaultWidth: 100,
            flex: 1
        },
        {
            name: "summary",
            header: "Summary",
            // defaultWidth: 100,
            flex: 1
        },
        {
            name: "experience",
            header: "Experience",
            type: "number",
            flex: 1,
            render: ({ value }) => {
                return <div>{value}</div>;
            },
        },
        {
            name: "status",
            header: "Status",
            // defaultWidth: 100,
            flex: 1
        },
        {
            name: "location",
            header: "Location",
            // defaultWidth: 100,
            flex: 1
        },
        {
            name: "lastRolePlayed",
            header: "Last RolePlayed",
            // defaultWidth: 100,
            flex: 1
        },
        {
            name: "softLockTill",
            header: "Soft Lock",
            // defaultWidth: 100,
            flex: 1,
            render: ({ value }) => {
                return <div>{value}</div>;
            },
        },
        {
            name: "profileUrl",
            header: "Profile",
            type: "string",
            defaultWidth: 140,
            render: ({ value }) => {
                if (value) {
                    return (
                        <a
                            className="btn btn-primary btn-sm"
                            href={value}
                            target="_blank"
                            rel="noreferrer"
                            title="Download Profile"
                        >
                            {" Download Profile"}
                        </a>
                    );
                } else {
                    return <span></span>;
                }
            },
        },
        {
            header: "Actions",
            // defaultFlex: 1,
            flex: 1,
            render: ({ data }) => {
                return (
                    <div>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                                openActionsModal(data);
                            }}
                        >
                            Actions
                        </button>
                    </div>
                );
            },
        },
    ];
    const filterValue = [
        { name: "id", operator: "eq", type: "number", value: null },
        { name: "email", operator: "contains", type: "string", value: "" },
        { name: "skill", operator: "contains", type: "string", value: "" },
        { name: "secondarySkill", operator: "contains", type: "string", value: "" },
        { name: "summary", operator: "contains", type: "string", value: "" },
        { name: "status", operator: "contains", type: "string", value: null },
        { name: "location", operator: "contains", type: "string", value: null },
        { name: "lastRolePlayed", operator: "contains", type: "string", value: null },
        { name: "softLockTill", operator: "contains", type: "string", value: null },
    ];
    return (
        <>
            <h4 className="items-center d-flex my-4 justify-content-center">
                Resume Uploaded Candidate Report
            </h4>
            <div className="container">
                <div className="row">
                    <ReactDataGrid
                        columns={columns}
                        dataSource={dataSource}
                        idProperty="id"
                        style={gridStyle}
                        pagination
                        defaultLimit={100}
                        loading={loading}
                        defaultFilterValue={filterValue}
                        // enableSelection={true}
                        rowHeight={null}
                    />
                </div>
            </div>
            {actionsModal ? (
                <ModalComponent
                    modalId={"candidate-actionmodal"}
                    title={"Actions"}
                    showFooter={true}
                    onCloseClick={onActionsModalClose}
                    footer={
                        <>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onActionsModalClose}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={updateCandidateStatus}
                            >
                                Save
                            </button>
                        </>
                    }
                    modalBody={<ActionsForm />}
                />
            ) : null}
        </>
    );
}
