import { useEffect } from "react";
import { EMPLOYEE_DASHBOARD_DATA } from "../../data/AppData";
import CardComponent from "../cardComponent/CardComponent";
import EmployeeSatisfactionSurvey from "../employeeSatisfactionSurvey/EmployeeSatisfactionSurvey";
import OverLay from "./OverLay";

export default function EmployeeDashboard() {
  return (
    <>
      <OverLay>
        <div
          className="container mt-4 mb-4 client-dashboard-hero"
          style={{
            backgroundImage: "url(./assets/images/tes-hero.jpg)",
            backgroundColor: "#887890",
          }}
        >
          <div className="row g-4">
            <div className="col-12 col-md-6">
              <div className="py-4">
                <p>
                  Welcome to TES (Telstra Employee Service)-a platform
                  showcasing your engagement with Telstra and the journey you
                  are in along with Persistent.
                </p>
                {/* <p>
                Use this platform to learn about your team structure, training
                recommendations and career planning in this entire journey.
              </p> */}
                <p>
                  Employee satisfaction is key ingredient in our engagement with
                  Telstra and we are listening you.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </OverLay> */}
        <div className="container">
          <div className="row g-4">
            <div className="col-2 col-md-2">
              <h5>Quick Links</h5>
            </div>
            <div className="col-2 col-md-6">
              <h6>
                <a href="#">Persistent HR</a>
              </h6>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 pt-2 order-2 order-lg-1">
              <div className="row g-4">
                {EMPLOYEE_DASHBOARD_DATA.map((data, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-6" key={index}>
                      <CardComponent {...data} contentAlign="center" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4 mb-lg-0 order-1 order-lg-2 d-flex pt-2">
              <span className="vr h-100 d-none d-md-block ms-2 me-4"></span>
              <EmployeeSatisfactionSurvey />
            </div>
          </div>
        </div>
      </OverLay>
    </>
  );
}
