import { useEffect, useState } from "react";
import { CANDIDATE_DASHBOARD_DATA } from "../../data/AppData";
import CardComponent from "../cardComponent/CardComponent";
import { showToast } from "../../util";
import { getCandidateSkillGapAnalytics } from "../../networking/apiFunctions/adminApiFunctions";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import OverLay from "./OverLay";

export default function CandidateDashboard({ userDetails }) {
  const { showOverlay, hideOverlay } = useAppLayoutContext();
  const [dashboardCards, setDashboardCards] = useState(
    CANDIDATE_DASHBOARD_DATA
  );

  const fetchAssessmentDetails = async () => {
    showOverlay();
    try {
      const res = await getCandidateSkillGapAnalytics(userDetails.userId);
      let apiData = res;
      if (apiData && apiData.length > 0) {
        localStorage.setItem("isCandidateAssessmentCompleted", true)
        const result = apiData[0];
        const newdashboard = CANDIDATE_DASHBOARD_DATA.slice(1);
        newdashboard.unshift({
          // link: "#",
          title: "Skill Assessments",
          cardBody: (
            <div>
              <p className="fw-bold">Last Assessment Status</p>
              <p>Score: {result.score}</p>
              <p>Status: {result.status}</p>
            </div>
          ),
          // btnText: "Know More",
        });
        setDashboardCards(newdashboard);
      }
    } catch (error) {
      showToast({ message: "Oops!! Something went wrong.", type: "error" });
    } finally {
      hideOverlay();
    }
  };
  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  return (
    <OverLay>
      <div
        className="container mt-4 mb-4 candidate-dashboard-hero"
        style={{
          backgroundImage: "url(./assets/images/tcap-hero.jpg)",
          backgroundColor: "#a49896",
        }}
      >
        <div className="row g-4">
          <div className="col-12 col-md-6">
            <div className="py-4">
              <p>Welcome to the Telstra Candidate Assessment Portal!</p>
              <p>
                Your way to get your skills assessed, skill gaps addressed and
                learning recommendations for upskilling. We are here to support
                you in your journey to work with Telstra.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-4">
          {dashboardCards.map((data, index) => {
            return (
              <div className="col-12 col-sm-6 col-md-4" key={index}>
                <CardComponent {...data} contentAlign="center" />
              </div>
            );
          })}
        </div>
      </div>
    </OverLay>
  );
}
