export default function ModalComponent({
    modalId,
    title,
    modalBody,
    onCloseClick,
    showFooter = false,
    footer
}) {
    return (<div className="modal d-block" id={modalId}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="btn-close" aria-labelledby="Close" onClick={onCloseClick}></button>
                </div>
                <div className="modal-body">
                    {
                        modalBody
                    }
                </div>
                {
                    showFooter &&
                    <div className="modal-footer">
                        {
                            footer
                        }
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                }
            </div>
        </div>
    </div>)
}