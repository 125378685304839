import React from "react";

const DownloadFile = () => {
    const onButtonClick = () => {
        const pdfUrl = "Questions.xlsx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "MCQ_Template.xlsx"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className="me-5 d-flex justify-content-end">
            <button onClick={onButtonClick} className="btn btn-primary justify-content-center col-md-5">
                MCQ File Template <span><img src={"assets/images/download-2.png"} className="col-md-2" /></span>
            </button>
        </div>

    );
};

export default DownloadFile;