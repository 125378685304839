import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import Helpers from "./Helper";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext"
import { getMcqTypes } from "../../networking/apiFunctions/candidateApiFunctions.js";
import { AxiosError } from "axios";
import { postCreateCognitoUser } from "../../networking/apiFunctions/serviceApiFunctions.js";

const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/Create-User",
    title: "Create User",
    active: true,
  },
]

const Registration = (props) => {
  const { setFormType } = props;
  const [skill, setMCQsList] = useState([]);
  const [primarySkill_, setprimarySkill] = useState("");
  const [typeOfUser, setTypeOfuser] = useState("");
  const [employeeType_, setEmployeeType] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const { updateBreadcrumbData, showOverlay, hideOverlay } = useAppLayoutContext();
  updateBreadcrumbData(breadcrumbs);

  useEffect(() => {
    handleGetMcqTypes()
  }, []);

  const handleGetMcqTypes = async () => {
    if (isLoading) { return }
    try {
      showOverlay()
      setIsLoading(true)
      const result = await getMcqTypes()
      setMCQsList(result)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err)
        showToast({ message: "Oops!! failed to fetch mcq types", type: "error" });
      }
    } finally {
      setIsLoading(false)
      hideOverlay()
    }
  }

  const userTypeList = [
    "Admin",
    "Candidate",
    "Employee",
    "Client",
    "Leader",
    "Interviewer"
  ];
  const employeeType = [
    "Internal",
    "External"
  ];



  const [formObject, setFormObject] = useState({
    userName: { value: "" },
    userEmail: { value: "" },
    userType: { value: "" },
    employeeType: {},
    primarySkill: {},
    createPassword: {},
    confirmPassword: {}
  });

  const onChangeData = (event) => {
    let newformObject = { ...formObject };
    let subObj = {};
    subObj["value"] = event.target.value;
    subObj["validation"] = true;
    if (event.target.id === "userEmail") {
      subObj["validation"] = Helpers.validateEmail(event.target.value);
    } else if (event.target.id === "createPassword") {
      subObj["validation"] = Helpers.validatePassword(event.target.value);
    } else if (!event.target.value) {
      subObj["validation"] = false;
    }

    if (event.target.id === 'confirmPassword') {
      if (newformObject.confirmPassword.value && newformObject.createPassword.value && newformObject.createPassword.value != event.target.value) {
        subObj["match"] = false;
      } else {
        subObj["match"] = true;
      }
    }
    newformObject[`${event.target.id}`] = subObj;
    setFormObject(newformObject);
  };

  const onSubmit = async () => {
    if (isLoading) { return }

    let validation = false;
    for (const key in formObject) {
      if (formObject[key].validation) {
        validation = true;
      } else {
        validation = false;
        formObject[key].validation = false;
      }
      if (typeOfUser != 'Candidate' && key === 'primarySkill') {
        formObject[key].validation = true;
      }
      if (key === 'confirmPassword' && !formObject[key].match) {
        validation = false;
      }
    }
    if (!validation) {
      setFormObject({ ...formObject });
    }
    if (!validation) {
      showToast({ type: "error", message: `*Mandatory fields are required` });
    } else {
      let body = {
        userType: typeOfUser,
        username: formObject.userName?.value,
        email: formObject.userEmail?.value,
        password: formObject.createPassword?.value,
        internalExternal: employeeType_,
        groupName: typeOfUser
      };


      if (primarySkill_) {
        body.skill = primarySkill_;
      }

      try {
        showOverlay()
        setIsLoading(true)
        await postCreateCognitoUser(body)
        showToast({ type: "success", message: "User has been created Successfully" })
        navigate("/dashboard")

      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          let errormsg = "Oops!! Something went wrong";
          if (err) {
            errormsg = err
          }
          showToast({ type: "error", message: errormsg });
        }

      } finally {
        setIsLoading(false)
        hideOverlay()
      }
    }
  };

  return (
    <div className="container login-container">
      <div className="row">
        <div className="col-md-3" />
        <div className="col-md-6 login-form-1">
          <h3>Create User</h3>

          <div className="mt-5 mx-3">
            <div className="form-group mb-4">
              <div className="row col-md-12">
                <label for="employeeType" className="col-md-4 fw-bold py-2">User Type </label>
                <select
                  className="form-control form-select col"
                  id="userType"
                  onChange={(e) => {
                    setTypeOfuser(e.target.value);
                    onChangeData(e);
                  }}
                  value={typeOfUser}
                >
                  <option value=''> Select User Type * </option>
                  {userTypeList.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
                {formObject.userType?.validation === false && (
                  <p className="red">Please select user type</p>
                )}
              </div>
            </div>

            <div className="form-group mb-4">
              <div className="row col-md-12">
                <label for="employeeType" className="col-md-4 fw-bold py-2"> Employee Type *</label>

                <select
                  className="form-control form-select col"
                  id="employeeType"
                  onChange={(e) => {
                    setEmployeeType(e.target.value);
                    onChangeData(e)
                  }}
                  value={employeeType_}
                >
                  <option value=''> Select Employee Type </option>
                  {employeeType.map((item, index) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
                {formObject.employeeType?.validation === false && (
                  <p className="red">Please select Employee Type</p>
                )}

              </div>
            </div>

            {typeOfUser === "Candidate" && (
              <>
                <div className="form-group mb-4">
                  <div className="row col-md-12">
                    <label for="primarySkill" className="col-md-4 fw-bold py-2">Skill *</label>
                    <select
                      className="form-control form-select col"
                      onChange={(e) => {
                        setprimarySkill(e.target.value);
                        onChangeData(e)
                      }}
                      id="primarySkill"
                      value={formObject.primarySkill?.value}
                    >
                      <option value=""> Select Skill *</option>
                      {skill.map((item) => {
                        return <option value={item.testType}>{item.testType}</option>;
                      })}
                    </select>
                    {formObject.primarySkill?.validation === false && (
                      <p className="red">Please select skill</p>
                    )}
                  </div>
                </div>
              </>
            )}


            <div className="form-group mb-4">
              <div className="row col-md-12">
                <label for="Username" className="col-md-4 fw-bold py-2"> Username </label>
                <input
                  type="text"
                  className="form-control col"
                  placeholder="Username *"
                  value={formObject.userName?.value}
                  id="userName"
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                />
                {formObject.userName?.validation === false && (
                  <p className="red">Please enter a valid user name</p>
                )}
              </div>
            </div>

            <div className="form-group mb-4">
              <div className="row col-md-12">
                <label for="userEmail" className="col-md-4 fw-bold py-2"> Email</label>
                <input
                  type="text"
                  className="form-control col"
                  placeholder="Email *"
                  value={formObject.userEmail?.value}
                  id="userEmail"
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                />
                {formObject.userEmail?.validation === false && (
                  <p className="red">Please enter a valid email</p>
                )}

              </div>
            </div>

            <div className="form-group mb-4">
              <div className="row col-md-12">
                <label for="createPassword" className="col-md-4 fw-bold py-2"> Password </label>
                <input
                  type="password"
                  className="form-control col"
                  placeholder="Create Password *"
                  value={formObject.createPassword?.value}
                  id="createPassword"
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                />
                {formObject.createPassword?.validation === false && (
                  <p className="red">password contain at least 8 characters contain at least one number one uppercase letter and one special character</p>
                )}
              </div>


            </div>

            <div className="form-group mb-4">
              <div className="row col-md-12">
                <label for="confirmPassword" className="col-md-4 fw-bold py-2"> Confirm Password</label>
                <input
                  type="password"
                  className="form-control col"
                  placeholder="Confirm Password *"
                  value={formObject.confirmPassword?.value}
                  id="confirmPassword"
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                />
                {formObject.confirmPassword?.validation === false && (
                  <p className="red">Please enter a valid confirmation password</p>
                )}
                {formObject.confirmPassword?.match === false && (
                  <p className="red">Password and confirmation password do not match</p>
                )}
              </div>
            </div>

            <div className="form-group form-switch"></div>
            <div className="form-group" style={{ paddingLeft: "30%" }}>
              <input
                type="submit"
                className="btnSubmit"
                value="Submit"
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Registration;
