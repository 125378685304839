import { fieldsData } from "./fieldsData.js";

export const surveyJson = {
  title: "Resume Generation Form (GIF)",
  description: "Update details for resume generation",
  pages: [
    {
      name: "PersonalDetails",
      elements: [
        {
          type: "text",
          name: "name",
          title: "Enter your Full Name",
          isRequired: fieldsData["name"],
        },
        {
          type: "text",
          name: "email",
          title: "Enter your Email ",
          // inputMask: "email",
          isRequired: fieldsData["email"],
        },
        {
          type: "text",
          name: "contactNumber",
          title: "Enter your contact number",
          isRequired: fieldsData["contactNumber"],
        },
        {
          type: "text",
          name: "experience",
          title: "Total Experience (YY.MM format)",
          //isRequired: fieldsData["experiance"],
        },
        {
          type: "comment",
          name: "summary",
          title:
            "Please describe your overall experience Summary in three to five points ",
          isRequired: fieldsData["summary"],
        },
        {
          type: "radiogroup",
          name: "cur_role",
          title: "Last Role Played",
          choices: [
            "Developer - Backend ",
            "Developer - Frontend ",
            "Developer - Full Stack",
            "Developer Team lead",
            "QA - Individual contributor",
            "QA Team lead",
            "Scrum master",
            "Business Analyst",
            "Technical Architect ",
            "Other",
          ],
          isRequired: fieldsData["cur_role"],
        },
        {
          type: "radiogroup",
          name: "domain_Specific_Expertise",
          title: "Domain specific expertise",
          choices: [
            "Telecom domain 1-3 Years",
            "Telecom domain > 3 Years",
            "BFSI",
            "Healthcare",
            "Agritech",
            "E-commerce and M-commerce",
            "AI and Data Science",
            "FMCG/Retail/ Manufacturing domain",
            "Logistic & Transportation",
          ],
          isRequired: fieldsData["domain_Specific_Expertise"],
        },
        {
          type: "radiogroup",
          name: "technical_Profile_Competency",
          title: "How about technical profile competency?",
          choices: [
            "Front End Developer (Please select your backend technologies from following question)",
            "Back End Developer (Please select your backend technologies from following question)",
            "Full Stack Developer (Please select your backend & frontend technologies from following question)",
            "QA/Tester (Please select your backend technologies from following question))",
            "Business Analyst",
            "Other",
          ],
          isRequired: fieldsData["technical_Profile_Competency"],
        },
        {
          type: "text",
          name: "other",
          title: "Other Technical competency",
          //isRequired: fieldsData["experiance"],
        },
        {
          type: "checkbox",
          name: "frontend_Competence",
          title: "Hands on Frontend experience (please check as applicable)",
          choices: [
            "Not Applicable",
            "React",
            "React + Redux",
            "Angular",
            "Vue JS",
            "Jinja",
            "Jinja 2",
            "Mako",
            "String templates",
            "Backbone.js",
            "Ember.js",
            "Expert in HTML/CSS/JavaScript",
            "TypeScript",
            "Bootstrap",
          ],
          isRequired: fieldsData["frontend_Competence"],
        },
        {
          type: "checkbox",
          name: "devops_Tool_Competence",
          title: "Hands on Devops tool competence (please check as applicable)",
          choices: [
            "Not Applicable",
            "Bamboo",
            "Jenkins",
            "Ansible",
            "Terraform",
            "Git",
            "AWS CI/CD",
            "Azure DevOps",
            "Docker",
            "Kubernetes",
            "API gateway",
            "Kafka",
            "RabbitMQ",
            "AWS SES",
          ],
          isRequired: fieldsData["devops_Tool_Competence"],
        },
        {
          type: "checkbox",
          name: "backend_Competence",
          title: "Backend competence (please check as applicable)",
          choices: [
            "Not Applicable",
            "Java",
            "Spring-boot",
            "Microservices",
            "REST APIs",
            "Junit",
            "NodeJS & ExpressJS",
            "Core Python",
            "Core Python + Django",
            "Core Python + Flask",
            "Core Python + Fast API",
            "Core Python + Bottle",
            "Core Python + Pyramid",
            ".Net and related technologies",
          ],
          isRequired: fieldsData["backend_Competence"],
        },
        {
          type: "checkbox",
          name: "quality_Engineering_Tool_Competence",
          title:
            "Quality engineering tool competence (please check as applicable)",
          choices: [
            "No Experience",
            "Java Selenium",
            "Appium",
            "JMeter",
            "Automation testing",
            "Junit",
            "Postman",
            "TestNG",
            "SoapUI",
            "UIPath",
            "RPA",
            "Cucumber",
            "Unittests",
            "PyTest",
            "Python Selenium",
          ],
          isRequired: fieldsData["quality_Engineering_Tool_Competence"],
        },
        {
          type: "checkbox",
          name: "cloud_Server_Exposure",
          title: "Hands on Cloud server exposure (please check as applicable)",
          choices: [
            "No Experience",
            "AWS",
            "GCP",
            "MS Azure",
            "Digital Ocean",
            "Private cloud/Datacenters",
            "PCF",
          ],
          isRequired: fieldsData["cloud_Server_Exposure"],
        },
        {
          type: "checkbox",
          name: "database_Exposure",
          title: "Hands on Database exposure (please check as applicable)",
          choices: [
            "No Experience",
            "MySQL",
            "MS SQL",
            "Posgres",
            "AWS RDS",
            "Graph DB",
            "Graph QL",
            "Cassandra",
            "Hbase",
            "MongoDB or any other document DB",
            "DynamoDB",
          ],
          isRequired: fieldsData["database_Exposure"],
        },
        {
          type: "checkbox",
          name: "process_Knowledge",
          title:
            "Software development methodologies & framework (please check as applicable)",
          choices: [
            "No Experience",
            "Scrum",
            "Kanban",
            "XP",
            "Waterfall",
            "Other",
          ],
          isRequired: fieldsData["process_Knowledge"],
        },
        {
          type: "comment",
          name: "certification",
          title:
            "Any certifications obtained? Pls describe your certification details and add year of certification done",
          isRequired: fieldsData["certification"],
        },

        {
          type: "panel",
          name: "project1",
          title:
            "Please describe your project 1 details against below headers. ",
          elements: [
            {
              type: "text",
              name: "p1name",
              title: "Project Name",
              isRequired: fieldsData["p1name"],
            },
            {
              type: "comment",
              name: "p1desc",
              title: "Brief Description",
              isRequired: fieldsData["p1desc"],
            },
            {
              type: "text",
              name: "p1duration",
              title: "Duration",
              isRequired: fieldsData["duration"],
            },
            {
              type: "comment",
              name: "p1Techstackused",
              title: "Tech stack used",
              isRequired: fieldsData["p1Techstackused"],
            },
            {
              type: "comment",
              name: "p1Contributionsandachievements",
              title: "Contributions and achievements",
              isRequired: fieldsData["p1Contributionsandachievements"],
            },
            {
              type: "comment",
              name: "p1Keylearnings",
              title: "Key learnings",
              isRequired: fieldsData["p1Keylearnings"],
            },
          ],
        },
        {
          type: "panel",
          name: "project2",
          title:
            "Please describe your project 2 details against below headers. ",
          elements: [
            {
              type: "text",
              name: "p2name",
              title: "Project Name",
              isRequired: fieldsData["p1name"],
            },
            {
              type: "comment",
              name: "p2desc",
              title: "Brief Description",
              isRequired: fieldsData["p1desc"],
            },
            {
              type: "text",
              name: "p2duration",
              title: "Duration",
              isRequired: fieldsData["duration"],
            },
            {
              type: "comment",
              name: "p2Techstackused",
              title: "Tech stack used",
              isRequired: fieldsData["p1Techstackused"],
            },
            {
              type: "comment",
              name: "p2Contributionsandachievements",
              title: "Contributions and achievements",
              isRequired: fieldsData["p1Contributionsandachievements"],
            },
            {
              type: "comment",
              name: "p2Keylearnings",
              title: "Key learnings",
              isRequired: fieldsData["p1Keylearnings"],
            },
          ],
        },
        {
          type: "panel",
          name: "project3",
          title:
            "Please describe your project 3 details against below headers. ",
          elements: [
            {
              type: "text",
              name: "p3name",
              title: "Project Name",
              isRequired: fieldsData["p1name"],
            },
            {
              type: "comment",
              name: "p3desc",
              title: "Brief Description",
              isRequired: fieldsData["p1desc"],
            },
            {
              type: "text",
              name: "p3duration",
              title: "Duration",
              isRequired: fieldsData["duration"],
            },
            {
              type: "comment",
              name: "p3Techstackused",
              title: "Tech stack used",
              isRequired: fieldsData["p1Techstackused"],
            },
            {
              type: "comment",
              name: "p3Contributionsandachievements",
              title: "Contributions and achievements",
              isRequired: fieldsData["p1Contributionsandachievements"],
            },
            {
              type: "comment",
              name: "p3Keylearnings",
              title: "Key learnings",
              isRequired: fieldsData["p1Keylearnings"],
            },
          ],
        },
      ],
    },
  ],
  completeText: "Submit",
  completedHtml: "Thank you for your feedback!",
};
