import { useState, useEffect } from "react"
import React from 'react'
import { Validators } from "../../utils/Auth";

const SubjectInput = (props) => {
    const { test, setTest, editMode, totalQuestionsFromAllTopics, setTotalQuestionsFromAllTopics } = props;
    const [subjectArray, setSubjectArray] = useState([])
    const [disabledBtn, setDisabledBtn] = useState(true)
    const [formErrors, setFormErrors] = useState({})

    const initialState = {
        subject: "",
        numOfQuestions: 0,
        numOfEasyQuestions: 0,
        numOfMediumQuestions: 0,
        numOfHardQuestions: 0,
    }
    const [topic, setTopic] = useState(initialState)
    const [remainingQuestions, setRemainingQuestions] = useState(0)

    useEffect(() => {
        setRemainingQuestions(test.numberOfQuestions)
    }, [test])



    useEffect(() => {
        setTest({
            ...test,
            mcqTypesDetails: subjectArray,
            numOfEasyQuestions: subjectArray.reduce((acc, curr) => {
                return acc + parseInt(curr.numOfEasyQuestions)
            }, 0),
            numOfMediumQuestions: subjectArray.reduce((acc, curr) => {
                return acc + parseInt(curr.numOfMediumQuestions)
            }, 0),
            numOfHardQuestions: subjectArray.reduce((acc, curr) => {
                return acc + parseInt(curr.numOfHardQuestions)
            }, 0),
        })
        console.log("🚀 ~ FINAL NO OF QSNS", subjectArray.reduce((acc, curr) => {
            return acc + parseInt(curr.numOfQuestions)
        }, 0))
        setTotalQuestionsFromAllTopics(subjectArray.reduce((acc, curr) => {
            return acc + parseInt(curr.numOfQuestions)
        }, 0))
        setTopic(initialState)
    }, [subjectArray])

    useEffect(() => {
        setRemainingQuestions(test.numberOfQuestions - totalQuestionsFromAllTopics)
        // setDisabledBtn(test.numberOfQuestions - totalQuestionsFromAllTopics)
    }, [totalQuestionsFromAllTopics])


    useEffect(() => {
        //     topic.numOfMediumQuestions +
        //     topic.numOfHardQuestions === topic.numOfQuestions, topic.subject !== "", topic.numOfQuestions !== 0, test.numberOfQuestions !== 0, test.numberOfQuestions >= topic.numOfQuestions, totalQuestionsFromAllTopics === test.numberOfQuestions, remainingQuestions)
        if (topic.numOfEasyQuestions +
            topic.numOfMediumQuestions +
            topic.numOfHardQuestions === topic.numOfQuestions
            && topic.subject !== ""
            && topic.numOfQuestions !== 0
            && test.numberOfQuestions !== 0
            && test.numberOfQuestions >= topic.numOfQuestions
            // && totalQuestionsFromAllTopics === test.numberOfQuestions
        ) {
            console.log("🚀 ~ useEffect ~ subjectArray.length, totalQuestionsFromAllTopics, test.numberOfQuestions", subjectArray.length, totalQuestionsFromAllTopics, test.numberOfQuestions)
            if (subjectArray.length && totalQuestionsFromAllTopics > test.numberOfQuestions) {
                setDisabledBtn(true)
            }
            if (remainingQuestions === 0) {
                setDisabledBtn(true)
            }
            setDisabledBtn(false)
            // setSubjectArray([...subjectArray, topic])
        } else {
            setDisabledBtn(true)
        }
    }, [topic, test.numberOfQuestions, remainingQuestions])


    return (
        <div className=" mt-4 mb-5 py-2 px-4  bg-grey rounded shadow-lg">
            <div className="row col-xl-12 mb-3 mt-3">
                <h4>Topic</h4>
                <div className='col-xl-4'>
                    <div className='row col-md-12'>
                        <label htmlFor="subName" className='col-md-7 me-2' style={{ marginTop: "5px" }}>Topic</label>
                        <input
                            type="text"
                            className="form-control col"
                            placeholder=""
                            value={topic.subject}
                            id="subName"
                            onChange={(e) => {
                                const entity = { entityValue: e.target.value, entityName: "Topic Name", minLength: 4, maxLength: 20 }
                                const error = [Validators.validateEmptyString(entity),
                                Validators.validateStringLength(entity),
                                Validators.validateStringWithoutSpecialCharacters(entity)]
                                console.log("🚀 ~ SubjectInput ~ error:", error)
                                setFormErrors({ ...formErrors, subject: error })
                                setTopic({
                                    ...topic,
                                    subject: e.target.value,
                                })
                            }}
                        />
                        {formErrors.subject && formErrors.subject !== "" && (
                            <ul className="red">{formErrors.subject.filter(el => el).map((err) => <li>{err}</li>)}</ul>
                        )}
                    </div>
                </div>

                <div className='col-xl-4'>

                    <div className='row col-md-12'>
                        <label htmlFor="noOfQuestions" className='col-md-8' style={{ marginTop: "5px" }}>No Of Questions in topic</label>
                        <input
                            type="number"
                            className="form-control col"
                            placeholder="Enter no of questions for this subject in this exam. Default is 30 *"
                            value={topic.numOfQuestions}
                            min={0}
                            // max={remainingQuestions}
                            id="noOfQuestions"
                            onChange={(e) => {
                                setTopic({
                                    ...topic,
                                    numOfQuestions: e.target.value ? parseInt(e.target.value) : 0,
                                })
                                console.log("🚀 ~ SubjectInput ~ topic.numOfQuestions:", topic)
                                const entity = { entityValue: e.target.value, entityName: "Number of quesions per Topic", minValue: 0, maxValue: remainingQuestions, additionalMessage: `You can't add any more questions for this topic as it will exceed the number of questions available in the exam.` }
                                const error = [Validators.validateNumberRange(entity)]
                                setFormErrors({ ...formErrors, numOfQuestions: error })
                            }}
                        />
                        {formErrors.numOfQuestions && formErrors.numOfQuestions !== "" && (
                            <ul className="red">{formErrors.numOfQuestions.filter(el => el).map((err) => <li>{err}</li>)}</ul>
                        )}
                    </div>
                </div>

            </div>

            <div className="row col-xl-12 mb-5">
                <div className='col-xl-4'>
                    <div className='row col-md-12'>
                        <label htmlFor="subName" className='col-md-4' style={{ marginTop: "5px", width: '200px' }}>Easy Questions</label>
                        <input
                            type="number"
                            className="form-control col"
                            placeholder=""
                            value={topic.numOfEasyQuestions}
                            min={0}
                            id="subName"
                            onChange={(e) => {
                                setTopic({
                                    ...topic,
                                    numOfEasyQuestions: e.target.value ? parseInt(e.target.value) : 0,
                                })
                            }}
                        />
                    </div>
                </div>

                <div className='col-xl-4'>
                    <div className='row col-md-12'>
                        <label htmlFor="noOfQuestions" className='col-md-8' style={{ marginTop: "5px" }}>Medium Questions</label>
                        <input
                            type="number"
                            className="form-control col"
                            placeholder="Enter no of questions for this subject in this exam. Default is 30 *"
                            value={topic.numOfMediumQuestions}
                            min={0}
                            id="noOfQuestions"
                            onChange={(e) => {
                                setTopic({
                                    ...topic,
                                    numOfMediumQuestions: e.target.value ? parseInt(e.target.value) : 0,
                                })
                            }}
                        />
                    </div>
                </div>

                <div className='col-xl-4'>
                    <div className='row col-md-12'>
                        <label htmlFor="noOfQuestions" className='col-md-8' style={{ marginTop: "5px" }}>Hard Questions</label>
                        <input
                            type="number"
                            className="form-control col"
                            placeholder="Enter no of questions for this subject in this exam. Default is 30 *"
                            value={topic.numOfHardQuestions}
                            min={0}
                            id="noOfQuestions"
                            onChange={(e) => {
                                setTopic({
                                    ...topic,
                                    numOfHardQuestions: e.target.value ? parseInt(e.target.value) : 0,
                                })
                            }}
                        />
                    </div>
                </div>

                <div className='col-xl-12  mt-3'>
                    <div className='row col-md-12'>
                        <div className="items-end d-flex justify-content-end">
                            {!editMode && (<div>
                                <button type="submit" disabled={disabledBtn} className={`${disabledBtn ? 'disableColor' : 'btn btn-primary'}`} onClick={() => {
                                    // Add an id to each subject
                                    // {`${disabledBtn ? '': 'btn btn-primary'}`
                                    setSubjectArray([...subjectArray, topic])
                                }}>Add Subject</button>

                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default SubjectInput