import "../Dashboard.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  CANDIDATE_NAV_DATA,
  CLIENT_NAV_DATA,
  EMPLOYEE_NAV_DATA,
  ADMIN_NAV_DATA,
} from "../../data/HEADER_NAV_DATA";
import { useEffect, useState } from "react";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import "./Header.css";
import { userLogout } from "../../networking/apiFunctions/serviceApiFunctions";
import axios, { AxiosError } from "axios";
import { showToast } from "../../util";
import { API_HOST_URL } from "../../constants";
import { logout } from "../../utils/logoutAllTabs";

const Header = (props) => {
  const [navBarData, setNavBarData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(null);
  const [appName, setAppName] = useState(null);
  const [isOverLay, setShowOverlay] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const { breadcrumbData, showOverlay, hideOverlay } = useAppLayoutContext();
  const [isLoading, setIsLoading] = useState(false)

  const [localUserData, setLocalUserData] = useState(localStorage.getItem("userValues") ? JSON.parse(localStorage.getItem("userValues")) : {})
  const [localJwt, setLocalJwt] = useState(localStorage.getItem("jwt"))
  const [browerConcurrentLogin, setBrowerConcurrentLogin] = useState(localStorage.getItem("browerConcurrentLogin"))

  useEffect(() => {
    handleResize();
    window.addEventListener('beforeunload', handleApi);
    return (() => {
      window.removeEventListener('beforeunload', handleApi)
    })
  }, []);

  const handleApi = () => {

    if (parseInt(browerConcurrentLogin) > 1) {
      localStorage.setItem("browerConcurrentLogin", (parseInt(browerConcurrentLogin) - 1))
      return
    }
    localStorage.clear()
    const payload = { userEmail: localUserData.userEmail }

    axios({
      method: 'delete', //you can set what request you want to be
      url: `${API_HOST_URL}/api/v1/logout`,
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localJwt}`
      }
    })
    // await userLogout(payload)
  }


  useEffect(() => {
    if (localStorage.getItem("userValues")) {
      setUserDetails(JSON.parse(localStorage.getItem("userValues")));
    }
  }, [location.pathname]);


  useEffect(() => {
    if (userDetails) {
      switch (userDetails.userType) {
        case "Employee":
          setNavBarData(EMPLOYEE_NAV_DATA);
          setAppName("Telstra Employee Service");
          break;
        case "Candidate":
          setNavBarData(CANDIDATE_NAV_DATA);
          setAppName("Telstra Candidate Assessment Portal");
          break;
        case "Admin":
          setNavBarData(ADMIN_NAV_DATA);
          setAppName("Telstra Admin Portal");
          break;
        case "Client":
          setNavBarData(CLIENT_NAV_DATA);
          setAppName("Persistent Smart Search");
          break;
        default:
          //   setNavBarData(CANDIDATE_NAV_DATA);
          break;
      }
    }
  }, [userDetails]);

  //function to handle resize event
  function handleResize() {
    if (window.innerWidth <= 768) {
      setIsCollapse(true);
    } else {
      setIsCollapse(false);
      setShowOverlay(false);
      const hamburgerButtonClick = new CustomEvent(
        "btn:onhamburgerbuttonclick",
        {
          bubbles: false,
          detail: {
            emit: () => true,
            isOverlay: () => false,
            navData: () => navBarData,
          },
        }
      );
      dispatchEvent(hamburgerButtonClick);
    }
  }

  //function to handle overlay
  function handleOverlay() {
    setShowOverlay(!isOverLay);
    const hamburgerButtonClick = new CustomEvent("btn:onhamburgerbuttonclick", {
      bubbles: false,
      detail: {
        emit: () => true,
        isOverlay: () => !isOverLay,
        navData: () => navBarData,
      },
    });
    dispatchEvent(hamburgerButtonClick);
  }

  //Event listener for window resize
  window.addEventListener("resize", handleResize);

  const handleLogout = async () => {
    if (isLoading) { return }
    try {
      showOverlay()
      setIsLoading(true)

      const payload = { userEmail: localUserData.userEmail }
      await userLogout(payload)
      navigate("/")
      props.setIsUserLoggedIn(false)
      logout()

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        console.log(err)
        showToast({ message: "Oops!! failed to logout", type: "error" });
      }

    } finally {
      setIsLoading(false)
      hideOverlay()
    }
  }

  return (
    <>
      <div className="header">
        <div>
          <span className="pt-1 fw-bold text-center fs-3">{appName}</span>
          {userDetails && userDetails.userType === "Client" ? (
            <>
              <br />
              <span
                className="ps-2 fst-italic"
                style={{ borderLeft: "solid 6px #fd5f07" }}
              >
                One stop inventory of highly skilled professionals specially
                tailored for Telstra
              </span>
            </>
          ) : null}
        </div>
        {
          <ul className="navbar-nav d-flex flex-row sm-navbar-top-right">
            {!isCollapse ? (
              navBarData.map((item, index) => {
                const { route, target } = item
                if (localStorage.getItem("isCandidateAssessmentCompleted")?.toLowerCase?.() === 'true'
                  && userDetails && userDetails.userType === 'Candidate' && route === '/quiz-home') {
                  return null
                }

                return (
                  <li className="nav-item" key={index}>
                    <Link
                      to={route}
                      className="nav-link"
                      target={target}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })
            ) : navBarData.length > 0 ? (
              <>
                <button
                  className="hamburger"
                  onClick={() => {
                    setShowOverlay(!isOverLay);
                    handleOverlay();
                  }}
                  type="button"
                >
                  <span className="hamburger-line first-line" />
                  <span className="hamburger-line middle-line" />
                  <span className="hamburger-line last-line" />
                </button>
              </>
            ) : null}
            <li className="nav-item pt-2 pl-5">
              <span
                className="logout"
                onClick={handleLogout}
              >
                <i
                  className="fa fa-sign-out"
                  aria-hidden="true"
                  title="Log Out"
                ></i>
              </span>
            </li>
          </ul>
        }
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-2">
                {breadcrumbData.map((item, index) => {
                  return (
                    <Link
                      className={`breadcrumb-item text-decoration-none ${item.active !== true ? "" : "active"
                        }`}
                      to={item.route}
                      aria-current="page"
                      key={index}
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
