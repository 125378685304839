import { apiClient } from "../apiClient";


export const getCandidateSkillGapAnalytics = async (userId) => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/admin/getCandidateReport?userId=${userId}`,
        params: {}
    })
    return response
}

export const directlyUploadedCandidates = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/admin/directlyUploadedCandidates`,
        params: {}
    })
    return response
}

export const getSurveyDetails = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/survey/getSurveyDetails`,
        params: {}
    })
    return response
}

export const deleteSoftSkill = async (rowId) => {
    const response = await apiClient({
        method: 'delete',
        url: `/api/v1/resume/master/deleteSoftSkill/${rowId}`,
        params: {}
    })
    return response
}

export const putCandidateAssessmentPerformanceStatus = async (payload) => {
    const response = await apiClient({
        method: 'put',
        url: `/api/v1/admin/candidateStatus/performAction`,
        data: payload
    })
    return response
}

export const getCandidateAssessmentReport = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/admin/getCandidateReport`,
        params: {}
    })
    return response
}



