import { createContext, useContext, useState } from "react";

const LayoutContext = createContext("");

export const LayoutProvider = ({ children }) => {
  const layoutvalues = useAppLayout();
  return (
    <LayoutContext.Provider value={layoutvalues}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useAppLayoutContext = () => {
  return useContext(LayoutContext);
};

const useAppLayout = () => {
  const [breadcrumbData, setBreadCrumbData] = useState([]);
  const [overlay, setOverlay] = useState(0);
  const updateBreadcrumbData = (data) => {
    setBreadCrumbData(data);
  };
  const showOverlay = () => {
    setOverlay((pre) => pre + 1);
  };
  const hideOverlay = () => {
    setOverlay((pre) => pre - 1);
  };
  return {
    breadcrumbData,
    updateBreadcrumbData,
    overlay,
    showOverlay,
    hideOverlay,
  };
};
