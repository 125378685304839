export const CANDIDATE_NAV_DATA = [
  {
    route: "/dashboard",
    title: "Dashboard",
  },
  {
    route: "/quiz-home",
    title: "Skill Assessments",
  },
  {
    route: "/skill-gap-analytics",
    title: "Skill Gap Analytics",
  },
  {
    route: "/recommendations",
    title: "Training Recommendations",
  },
];

export const EMPLOYEE_NAV_DATA = [
  {
    route: "/dashboard",
    title: "Dashboard",
  },
  {
    route: "#",
    title: "My Profile",
  },
  {
    route: "#",
    title: "Team Structure",
  },
  {
    route: "#",
    title: "Recommendations",
  },
  {
    route: "#",
    title: "Career Planning",
  },
];

export const CLIENT_NAV_DATA = [
  // {
  //   route: "https://www.persistent.com/",
  //   title: "About Persistent",
  //   target: "_blank",
  // },
  // {
  //   route: "#",
  //   title: "How we are handpicking the candidates for Telstra",
  // },
];

export const ADMIN_NAV_DATA = [
  {
    route: "/dashboard",
    title: "Dashboard",
  },
];
