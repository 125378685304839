import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./OverLay.css";

export default function OverLay({ children }) {
  const [isCollapse, setIsCollapse] = useState(false);
  const [navBarData, setNavBarData] = useState([]);
  useEffect(() => {
    window.addEventListener("btn:onhamburgerbuttonclick", comboboxHandler);
    return () => {
      window.removeEventListener("btn:onhamburgerbuttonclick", comboboxHandler);
    };
  }, []);
  const comboboxHandler = (e) => {
    setIsCollapse(e.detail.isOverlay());
    setNavBarData(e.detail.navData());
  };
  return (
    <>
      {isCollapse ? (
        <div className="overlay">
          <div className="menu">
            {navBarData.map((item, index) => {
              return (
                <ul className="nav-item" key={index}>
                  <Link
                    to={item.route}
                    className="nav-link"
                    target={item.target}
                  >
                    {item.title}
                  </Link>
                </ul>
              );
            })}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
