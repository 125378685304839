/* Make fields data true (required) or false (optional) */
export const fieldsData = {
  // by default required
  name: true,
  email: true,
  contactNumber: true,
  location: true,
  comfortable_Client_Location: true,
  long_leave_Plan: true,
  openShift: true,
  last_Role_Played: true,
  months_on_Bench: true,
  interview_Preferred_time_slot: true,
  domain_Specific_Expertise: true,
  technical_Profile_Competency: true,
  frontend_Competence: true,
  devops_Tool_Competence: true,
  backend_Competence: true,
  quality_Engineering_Tool_Competence: true,
  cloud_Server_Exposure: true,
  database_Exposure: true,
  process_Knowledge: true,
  summary: true,
  project1: true,
  project2: true,
  certification_detail: true,
  experience: true,

  // by default optional
  project3: false,
  certification: false,
  project_assignment_availability: false,
  attended_Internal_Client_Interview: false,
  tool_stack_worked: false,
  frontend_rating: false,
  backend_rating: false,
  html_Css_Java_Script_rating: false,
  aws_rating: false,
  devOps_rating: false,
};
