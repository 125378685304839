import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

export default function MCQTimer({ expiryTimestamp, onTimerExpire }) {
  const {
    seconds,
    minutes,
  } = useTimer({ expiryTimestamp, onExpire: onTimerExpire });
  // useEffect(() => {
  //   if (minutes == 0 && seconds == 59) {
  //     alert('Only one minute is left.')
  //   }
  // })

  return (
    <span className={minutes === 0 ? "text-danger fw-bold sm-text-glow-danger fs-3" : ""}>
      <span>{minutes < 10 ? '0' + minutes : minutes}</span>:<span>{seconds < 10 ? '0' + seconds : seconds}</span>
    </span>
  );
}