import { apiClient } from "../apiClient"

export const postCandidateResumeUpload = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/candidate/resumeSave`,
        data: payload
    })
    return response
}

export const getCandidateMcqSummary = async (userId) => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/candidate/getMCQSummary?userid=${userId}`,
        params: {}
    })
    return response
}

export const putCandidateAssessmentVideoUpload = async (payload) => {
    const response = await apiClient({
        method: 'put',
        url: `/api/v1/candidate/uploadFileVideo`,
        data: payload
    })
    return response
}

export const getCandidateMcqQuestions = async (userId) => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/candidate/getMcqQuestions?userid=${userId}`,
        params: {}
    })
    return response
}

export const postCandidateAssessmentResult = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/candidate/saveTestAnswers`,
        data: payload
    })
    return response
}

export const postCandidateSurvey = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/marketplace/candidateInfo/save`,
        data: payload
    })
    return response
}

export const getMcqTypes = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/candidate/mcqtypes`,
        params: {}
    })
    return response
}

export const postMcqTypes = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/candidate/mcqTypes`,
        data: payload
    })
    return response
}



export const getCandidateMcqQuestionsForTestType = async (testType) => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/candidate/getMcQuestions?testType=${testType}`,
        params: {}
    })
    return response
}

