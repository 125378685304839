import React from 'react'
import Multiselect from "multiselect-react-dropdown";

export const MultiDropdownComponent = ({ selectedOption, setSelectedOption, optionsList, placeholder, isSingleSelect }) => {

    return (
        <div>
            <Multiselect
                className="w-auto"
                displayValue="label"
                placeholder={placeholder}
                selectedValues={selectedOption}
                onSelect={(selectedOption, selectedItem) => {
                    setSelectedOption(selectedOption)
                }}
                onRemove={(selectedOption, removedItem) => {
                    setSelectedOption(selectedOption)
                }}
                showCheckbox={true}
                singleSelect={isSingleSelect}
                hideSelectedList={false}
                isObject={true}
                options={optionsList}
            />
        </div>
    )
}
