import ReactDataGrid from "@inovua/reactdatagrid-community";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import "@inovua/reactdatagrid-community/index.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import { getSurveyDetails } from "../../networking/apiFunctions/adminApiFunctions";

window.moment = moment;

const gridStyle = { minHeight: 550 };

export default function EmployeeSurveyReport() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const { updateBreadcrumbData } = useAppLayoutContext();

  const breadcrumbs = [
    {
      route: "/dashboard",
      title: "Home",
    },
    {
      route: "/employee-survey-report",
      title: "Employee Service Report",
      active: true,
    },
  ];

  const fetchEmployeeSurveyReport = async () => {
    setLoading(true);
    try {
      setLoading(false);
      //added by anshu kumar
      const response = await getSurveyDetails()
      let updateSourceData = response.data;
      setDataSource(updateSourceData || []);
    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast({ message: "Oops!! Something went wrong.", type: "error" });
    }
  };

  useEffect(() => {
    // call api and update the datasource
    updateBreadcrumbData(breadcrumbs);
    fetchEmployeeSurveyReport();
  }, []);

  const columns = [
    {
      name: "userName",
      header: "Name",
      defaultWidth: 110,
    },
    {
      name: "userEmail",
      header: "Email",
      defaultWidth: 200,
    },
    {
      name: "skill",
      header: "Skill",
      defaultWidth: 100,
    },
    {
      name: "employee_response",
      header: "Employee Response",
      defaultWidth: 180,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: [
          { id: true, label: "Satisfied" },
          { id: false, label: "Dissatisfied" },
        ],
      },
      render: ({ data }) => {
        if (data.employee_response === "Satisfied") {
          return <span className="text-success">Satisfied</span>;
        } else {
          return <span className="text-danger">Dissatisfied</span>;
        }
      },
    },
    {
      name: "functions_selected",
      header: "Functions",
      defaultWidth: 200,
      render: ({ value }) => {
        if (value && value.length > 0) {
          return (
            <p title={value} className="mb-0 text-truncate">
              {value}
            </p>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      name: "timestamp",
      header: "Updated Date/Time",
      defaultWidth: 300,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        // console.log(index);
        return {
          dateFormat: "DD/MM/YYYY hh:mm:ss A",
          cancelButton: false,
          highlightWeekends: false,
          placeholder:
            index === 1
              ? "Before updated date/time"
              : "After updated date/time",
        };
      },
      render: ({ value }) => {
        if (value) {
          return (
            <p
              title={moment(value).format("DD/MM/YYYY hh:mm:ss A")}
              className="mb-0 text-truncate"
            >
              {moment(value).format("DD/MM/YYYY hh:mm:ss A")}
            </p>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      name: "comments",
      header: "Comments",
      defaultFlex: 1,
      render: ({ value }) => {
        if (value) {
          return (
            <p title={value} className="mb-0 text-truncate">
              {value}
            </p>
          );
        } else {
          return <p></p>;
        }
      },
    },
  ];
  const filterValue = [
    { name: "userName", operator: "contains", type: "string", value: "" },
    { name: "userEmail", operator: "contains", type: "string", value: "" },
    { name: "skill", operator: "contains", type: "string", value: "" },
    { name: "employee_response", operator: "eq", type: "select", value: null },
    {
      name: "functions_selected",
      operator: "contains",
      type: "string",
      value: "",
    },
    { name: "timestamp", operator: "inrange", type: "date", value: null },
    { name: "comments", operator: "contains", type: "string", value: "" },
  ];
  return (
    <>
      <h4 className="items-center d-flex my-4 justify-content-center">
        Employee Service Report
      </h4>
      <div className="container">
        <div className="row">
          <ReactDataGrid
            columns={columns}
            dataSource={dataSource}
            idProperty="userId"
            style={gridStyle}
            pagination
            defaultLimit={100}
            loading={loading}
            enableColumnAutosize={true}
            defaultFilterValue={filterValue}
          // enableSelection={true}
          />
        </div>
      </div>
    </>
  );
}
