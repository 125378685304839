import { apiClient } from "../apiClient"

export const postSignin = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/login`,
        data: payload
    })
    return response
}

export const postCognitoSignin = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/cognito/signIn`,
        data: payload
    })
    return response
}

export const postCreateCognitoUser = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/cognito/createUser`,
        data: payload
    })
    return response
}

export const postSignup = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/signup`,
        data: payload
    })
    return response
}

export const getRoleList = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/resume/master/getRoleList`,
        params: {}
    })
    return response
}

export const getSoftSkillsList = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/resume/master/getSoftSkillList`,
        params: {}
    })
    return response
}

export const getLocationList = async () => {
    const response = await apiClient({
        method: 'get',
        url: `/api/v1/candidate/locations`,
        params: {}
    })
    return response
}

export const postCreateSoftSkill = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/resume/master/createSoftSkill`,
        data: payload
    })
    return response
}

export const deleteRoll = async (rowId) => {
    const response = await apiClient({
        method: 'delete',
        url: `/api/v1/resume/master/deleteRole/${rowId}`,
        params: {}
    })
    return response
}

export const postCreateRole = async (payload) => {
    const response = await apiClient({
        method: 'post',
        url: `/api/v1/resume/master/createRole`,
        data: payload
    })
    return response
}

export const userLogout = async (payload) => {
    const response = await apiClient({
        method: 'delete',
        url: `/api/v1/logout`,
        data: payload
    })
    return response
}