import { useCallback, useRef, useState } from "react";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "./ExternalCandidateSurvey.css";
import { surveyJson } from "../../data/externalCandidateSurvey";
import { AxiosError } from "axios";
import { showToast } from "../../util";
import { postCandidateSurvey } from "../../networking/apiFunctions/candidateApiFunctions";
import { useAppLayoutContext } from "../../contexts/LayoutContext";

function ExternalCandidateSurvey() {
  // useRef enables the Model object to persist between state changes
  const survey = useRef(new Model(surveyJson)).current;
  const { showOverlay, hideOverlay } = useAppLayoutContext()
  const [isLoading, setIsLoading] = useState(false)

  const displayResults = useCallback(async (survey) => {
    let resultData = {};
    if (isLoading) { return }

    for (const key in survey.data) {
      const answer = survey.getValue(key);
      if (Array.isArray(answer)) {
        resultData[key] = answer.join(";");
      } else {
        resultData[key] = answer;
      }
    }
    resultData["candidate_type"] = "external";
    // Send `resultData` to your web server
    const dataObject = resultData;


    setIsLoading(true)
    showOverlay()
    try {

      const response = await postCandidateSurvey(dataObject)
      showToast({ message: response, type: "success" })
      setIsLoading(false)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setIsLoading(false)
        showToast({ message: "Oops!! Something went wrong.", type: "error" });
      }
    } finally {
      hideOverlay()
    }
  }, []);

  survey.onComplete.add(displayResults);

  return (
    <>
      <Survey model={survey} id="surveyContainer" />

    </>
  );
}

export default ExternalCandidateSurvey;
