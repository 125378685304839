import { useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { AxiosError } from "axios";
import { showToast } from "../../util";
import { useAppLayoutContext } from "../../contexts/LayoutContext";
import ModalComponent from "../../components/ModalComponent";
import { getCandidateAssessmentReport, putCandidateAssessmentPerformanceStatus } from "../../networking/apiFunctions/adminApiFunctions";

const gridStyle = { minHeight: 550 };
const breadcrumbs = [
  {
    route: "/dashboard",
    title: "Home",
  },
  {
    route: "/candidate-assessment-report",
    title: "Candidate Report",
    active: true,
  },
];
const temp = [
  {
    id: 1,
    userId: 4,
    userName: "Sai Raj",
    userEmail: "employee@persistent.com",
    skill: "React",
    cutoffScore: 7,
    score: 6,
    status: "fail",
  },
  {
    id: 2,
    userId: 5,
    userName: "Pranav",
    userEmail: "employee2@persistent.com",
    skill: "React",
    cutoffScore: 7,
    score: 8,
    status: "pass",
  },
];
const initialState = { selectedAction: "" };

export default function CandidateAssessmentReport() {
  const { updateBreadcrumbData, showOverlay, hideOverlay } =
    useAppLayoutContext();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [selectedCandidateData, setSelectedCandidateData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(initialState);

  const onActionsModalClose = () => {
    setActionsModal(false);
    setSelectedCandidateData(null);
    setSelectedOption(initialState);
  };
  const openActionsModal = (data) => {
    setSelectedCandidateData(data);
    setActionsModal(true);
  };
  updateBreadcrumbData(breadcrumbs);

  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("userValues"))
  );

  const fetchAssessmentReport = async () => {
    if (loading) { return }

    setLoading(true);
    showOverlay()
    try {
      //Adding by anshu kumar
      const data = await getCandidateAssessmentReport()
      setDataSource(data || []);
    } catch (error) {
      showToast({ message: "Oops!! Something went wrong.", type: "error" });
    } finally {
      setLoading(false);
      hideOverlay()
    }
  };

  useEffect(() => {
    // call api and update the datasource
    fetchAssessmentReport();
  }, []);

  const updateCandidateStatus = async () => {
    if (loading) { return }

    if (selectedOption.selectedAction !== "") {
      showOverlay(true);
      try {
        let data = {
          userId: userDetails.userId, //adminId
          candidateUserId: selectedCandidateData.userId,
          candidateEmail: selectedCandidateData.userEmail,
          clientSelectedRejected: "", // Selected/Rejected
          promotionStatus: "",
          bookmarked: false,
        };
        if (selectedOption.selectedAction === "Promoted") {
          data["promotionStatus"] = "Promoted";
        }
        if (selectedOption.selectedAction === "Declined To Client") {
          data["promotionStatus"] = "Declined To Client";
        }
        if (selectedOption.selectedAction === "Bookmark") {
          data["bookmarked"] = true;
        }
        if (selectedOption.selectedAction === "Soft Lock") {
          data["softLockTill"] = new Date();
        }
        if (selectedOption.selectedAction === "Download_Resume") {
          console.log(selectedOption.selectedAction, selectedCandidateData.profileUrl);
          const link = document.createElement('a');
          link.href = selectedCandidateData.profileUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          showToast({ message: `Candidate resume download successfully`, type: "success" });
          onActionsModalClose();
          return true;
        } else {
          data["expiryDate"] = "2023-09-27";
          setLoading(true)
          const result = await putCandidateAssessmentPerformanceStatus(data)
          showToast({ message: result, type: "success" });
          onActionsModalClose();
          fetchAssessmentReport();
        }


      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          console.log(err)
          showToast({ message: "Oops!! Something went wrong.", type: "error" });
        }

      } finally {
        setLoading(false)
        hideOverlay(false);
      }
    } else {
      showToast({ type: "error", message: "Please select an action" });
    }
  }

  const handleOptionChange = (e) => {
    setSelectedOption((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const ActionsForm = () => {
    return (
      <>
        <div className="my-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <select
              className="form-select form-select-lg"
              name="selectedAction"
              onChange={handleOptionChange}
              value={selectedOption.selectedAction}
            >
              <option value="">Select Action</option>
              <option value="Promoted">Promoted to Client</option>
              <option value="Declined To Client">Declined to Client</option>
              <option value="Bookmark">Bookmark</option>
              <option value="Soft Lock">Soft Lock</option>
              <option value="Download_Resume">
                Download Resume
              </option>
            </select>
          </form>
        </div>
      </>
    );
  };
  const columns = [
    {
      name: "userName",
      header: "User Name",
      defaultWidth: 200,
    },
    {
      name: "skill",
      header: "Skill",
      defaultWidth: 100,
    },
    {
      name: "cutoffScore",
      header: "Cutoff Score", //"Cutoff Percentage"
      type: "number",
      defaultWidth: 100,
      render: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      name: "percentageScore",
      header: "Percentage Scored",
      type: "number",
      render: ({ value }) => {
        return <div>{value}%</div>;
      },
    },
    {
      name: "status",
      header: "Status",
      defaultWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: [
          { id: "TCAP rejected", label: "TCAP rejected" },
          { id: "TCAP cleared", label: "TCAP cleared" },
          { id: "Promoted To Client", label: "Promoted To Client" },
          { id: "Declined To Client", label: "Declined To Client" },
          { id: "Bookmarked", label: "Bookmarked" },
          { id: "Resume Uploaded", label: "Resume Uploaded" },
          { id: "CIF Rejected", label: "CIF Rejected" },
          { id: "CIF Cleared", label: "CIF Cleared" }
        ],
      },
      render: ({ value }) => {
        return <span className="text-success">{value}</span>;
      },
    },
    {
      header: "Soft Lock",
      defaultWidth: 100,
      render: ({ data }) => {
        const { softLockTill } = data;
        return <div>{softLockTill}</div>;
      },
    },
    {
      name: "skillAnalytics",
      header: "Skill Analytics",
      render: ({ data }) => {
        const { subScore_Detail } = data;
        return (
          <a href={`skill-gap-analytics?user_id=${data.userId}`}>
            {subScore_Detail.map((item, i) => (
              <div key={i}>
                {item.subjectName}-{item.subjectPercentage}%
              </div>
            ))}

          </a>
        );
      },
    },
    {
      header: "Actions",
      defaultFlex: 1,
      render: ({ data }) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                openActionsModal(data);
              }}
            >
              Actions
            </button>
          </div>
        );
      },
    },
  ];
  const filterValue = [
    { name: "userName", operator: "contains", type: "string", value: "" },
    { name: "userEmail", operator: "contains", type: "string", value: "" },
    { name: "skill", operator: "contains", type: "string", value: "" },
    { name: "cutoffScore", operator: "eq", type: "number", value: null },
    { name: "percentageScore", operator: "eq", type: "number", value: null },
    { name: "status", operator: "eq", type: "select", value: null },
  ];
  return (
    <>
      <h4 className="items-center d-flex my-4 justify-content-center">
        Candidate Report
      </h4>
      <div className="container">
        <div className="row">
          <ReactDataGrid
            columns={columns}
            dataSource={dataSource}
            idProperty="id"
            style={gridStyle}
            pagination
            defaultLimit={100}
            defaultFilterValue={filterValue}
            // enableSelection={true}
            rowHeight={null}
          />
        </div>
      </div>
      {actionsModal ? (
        <ModalComponent
          modalId={"candidate-actionmodal"}
          title={"Actions"}
          showFooter={true}
          onCloseClick={onActionsModalClose}
          footer={
            <>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onActionsModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateCandidateStatus}
              >
                Save
              </button>
            </>
          }
          modalBody={<ActionsForm />}
        />
      ) : null}
    </>
  );
}
