import React from 'react'
import ResumeDetails from '../../pages/ResumeUploadPages'
import ResourceSearchComponent from '../ResourceSearchComponent'

export const ResumeUpload = () => {
  return (
    <>
      <ResumeDetails />
    </>

  )
}
